import { useState, useEffect } from 'react';

import {
  FailedDataState,
  LoadingDataState,
  ReceivedDataState,
  NotRequestedDataState,
} from 'altus-datastate';

import { IN_DEV } from './constants';

/**
 * Calls the request and stores the result in the state,
 * taking care of dispatching the corresponding ASYNC_START
 * and ASYNC_END actions used by the altus-datastate library.
 *
 * @param  {Function} request The request to execute. MUST be memoized.
 */
const useRequest = (request) => {
  const [response, setResponse] = useState();
  const [dataState, setDataState] = useState(NotRequestedDataState);

  const setLoadingDataState = () => setDataState(LoadingDataState);

  useEffect(() => {
    let isSubscribed = true;

    Promise.resolve()
      .then(setLoadingDataState)
      .then(request)
      .then((response) => {
        if (isSubscribed) {
          setDataState(ReceivedDataState);
          setResponse(response);
        }
      })
      .catch((error) => {
        if (isSubscribed) {
          setDataState(FailedDataState);
        }
        if (IN_DEV) {
          console.error(error);
        }

        throw error;
      });

    return () => (isSubscribed = false);
  }, [request]);

  return [response, dataState];
};

export default useRequest;
