import castArray from 'lodash/castArray';
import { isImmutable } from 'immutable';

import { LoadingDataState, ReceivedDataState } from './dataState.constants';

export const getSummarizedDataState = (dataStates) =>
  anyLoadingDataState(dataStates) ? LoadingDataState : ReceivedDataState;

export const anyLoadingDataState = (dataStates) =>
  castArray(isImmutable(dataStates) ? dataStates.toJS() : dataStates)
    .filter(Boolean)
    .some((dataState) => dataState.isLoading());
