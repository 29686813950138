import { compose } from 'redux';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import React, { PureComponent } from 'react';
import { Grid, Button } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import BasePageTitle from './BasePageTitle';

class BasePageHeader extends PureComponent {
  render() {
    const {
      Icon,
      title,
      classes,
      endText = '',
      displayAddButton,
      addButtonOnClick,
      BasePageTitleProps,
    } = this.props;

    return (
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.root}
      >
        {isString(title) ? (
          <Grid xs item container alignItems="center">
            <BasePageTitle title={title} Icon={Icon} {...BasePageTitleProps} />
          </Grid>
        ) : (
          title
        )}
        {endText !== '' ? (
          <Grid item>
            <BasePageTitle title={endText} />
          </Grid>
        ) : null}
        {displayAddButton && addButtonOnClick && (
          <Grid item>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={addButtonOnClick}
            >
              Add
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }
}

const styles = (theme) => ({
  root: {
    padding: `${theme.spacing(2.25)}px ${theme.spacing(3)}px 0px`,
    marginBottom: theme.spacing(3),
  },
});

BasePageHeader.propTypes = {
  title: PropTypes.node,
};

export default compose(
  withStyles(styles, {
    name: 'BasePageHeader',
  }),
)(BasePageHeader);
