import { compose } from 'redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import { TableRow, TableBody, Table } from '.';

const SortableTableRow = sortableElement(TableRow);
const SortableTableBody = sortableContainer(TableBody);

const SortableTable = ({
  items,
  classes,
  renderIcon,
  onRowClick,
  onItemSorted,
  usePagination,
  useRowSelect,
  useDragHandle,
  disableSortBy,
  useGlobalFilter,
  ...rest
}) => {
  if (onRowClick && !useDragHandle) {
    console.warn(
      'If you want to provide an onRowClick() event handler with SortableTable, you must use a drag handle.',
    );
  }

  const { sortableTableHelperClass, ...tableClasses } = classes;

  const data = useMemo(() => items.valueSeq(), [items]);

  const tableBodyProps = useMemo(
    () => ({
      useDragHandle,
      lockAxis: 'y',
      onSortEnd: onItemSorted,
      helperClass: sortableTableHelperClass,
    }),
    [sortableTableHelperClass, onItemSorted, useDragHandle],
  );

  return (
    <Table
      items={data}
      disableSortBy
      classes={tableClasses}
      useGlobalFilter={false}
      TableBodyProps={tableBodyProps}
      TableRowComponent={SortableTableRow}
      TableBodyComponent={SortableTableBody}
      {...rest}
    />
  );
};

const styles = (theme) => ({
  sortableTableHelperClass: {
    // Ref.: https://github.com/clauderic/react-sortable-hoc#item-disappearing-when-sorting--css-issues
    zIndex: theme.zIndex.modal + 100,
  },
});

SortableTable.propTypes = {
  useDragHandle: PropTypes.bool,
  onItemSorted: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Immutable.Collection),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.node,
      ]),
    }),
  ).isRequired,
};

export default compose(React.memo, withStyles(styles))(SortableTable);
