import React from 'react';
import { compose } from 'redux';
import LastPageIcon from '@material-ui/icons/LastPage';
import withStyles from '@material-ui/styles/withStyles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import {
  Grid,
  Divider,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core';

const TablePaginationFooter = ({
  classes,
  gotoPage,
  nextPage,
  pageCount,
  pageIndex,
  canNextPage,
  showGoToPage,
  previousPage,
  canPreviousPage,
}) => {
  if (pageCount <= 1) {
    return null;
  }

  return (
    <Grid
      item
      container
      spacing={2}
      justifyContent="flex-end"
      alignItems="center"
      classes={{ root: classes.paginationFooter }}
    >
      <Grid item>
        <IconButton
          size="small"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FirstPageIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          <NavigateBeforeIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton size="small" onClick={nextPage} disabled={!canNextPage}>
          <NavigateNextIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <LastPageIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant="caption">
          {`Page ${pageIndex + 1} of ${pageCount}`}
        </Typography>
      </Grid>
      {showGoToPage && (
        <>
          <Divider flexItem orientation="vertical" />
          <Grid item>
            <TextField
              size="small"
              placeholder="Go to Page"
              defaultValue=""
              onChange={({ target }) => {
                const page = target.value ? Number(target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const styles = (theme) => ({
  paginationFooter: {
    padding: `${theme.spacing(2)}px 0`,
  },
});

// TODO: propTypes

export default compose(
  withStyles(styles, { name: 'TablePaginationFooter' }),
  React.memo,
)(TablePaginationFooter);
