import React from 'react';
import PropTypes from 'prop-types';

import {
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

const MultiSelectModalItem = ({ onClick, checked, name, description }) => (
  <ListItem button dense onClick={onClick}>
    <ListItemIcon>
      <Checkbox edge="start" disableRipple color="default" checked={checked} />
    </ListItemIcon>
    <ListItemText primary={name} secondary={description} />
  </ListItem>
);

MultiSelectModalItem.propTypes = {
  checked: PropTypes.bool,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MultiSelectModalItem;
