import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React, { PureComponent } from 'react';
import Launch from '@material-ui/icons/Launch';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, ButtonBase, Typography } from '@material-ui/core';

import Tile from '../Tile';
import { openInWindow } from '../utils';
import { TILE_CHIP_TYPES } from './Tile';

export const OPEN_IN = {
  SAME_PAGE: 'samePage',
  TAB: 'tab',
  WINDOW: 'window',
};

class NavigationTile extends PureComponent {
  onClick = () => {
    const { to, openIn } = this.props;

    if (openIn === OPEN_IN.WINDOW) {
      openInWindow({
        to,
      });
    }
  };

  render() {
    const { to, openIn, classes, breadcrumb, TileProps, ...rest } = this.props;

    return (
      <ButtonBase
        onClick={this.onClick}
        className={classes.root}
        to={openIn !== OPEN_IN.WINDOW ? to : undefined}
        component={openIn !== OPEN_IN.WINDOW ? Link : Grid}
        target={openIn === OPEN_IN.TAB ? '_blank' : undefined}
      >
        <Tile
          classes={{
            root: classes.tileRoot,
            iconRoot: classes.tileIcon,
            [`chip-${TILE_CHIP_TYPES.NOTIFICATION}`]: classes.tileNotification,
          }}
          TileProps={TileProps}
          {...rest}
        >
          <Typography
            variant="h6"
            align="center"
            color="primary"
            className={classes.tileTitle}
          >
            {breadcrumb}
            {openIn !== OPEN_IN.SAME_PAGE && (
              <Launch className={classes.externalIcon} />
            )}
          </Typography>
        </Tile>
      </ButtonBase>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  tileRoot: {
    '&:hover': {
      opacity: 0.9,
      background: theme.palette.action.hover,
    },
  },
  tileTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  tileNotification: {},
  tileIcon: {},
  externalIcon: {
    marginLeft: theme.spacing(1),
  },
});

NavigationTile.defaultProps = {
  openIn: OPEN_IN.SAME_PAGE,
};

NavigationTile.propTypes = {
  openIn: PropTypes.oneOf(Object.values(OPEN_IN)).isRequired,
};

export default compose(withStyles(styles, { name: 'NavigationTile' }))(
  NavigationTile,
);
