class DataState {
  constructor(type) {
    Object.entries(type).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  isLoading = () => this.requested && !this.received;
  isError = () => this.requested && this.received && this.error;
  isSuccess = () => this.requested && this.received && !this.error;
}

export default DataState;
