import React from 'react';
import { compose } from 'redux';
import { SvgIcon } from '@material-ui/core';

import {
  Expired,
  UpToDate,
  Rejected,
  ExpiresSoon,
  NotApproved,
  RequiresApproval,
  MissingRequirements,
} from '../Icons';

import { COMPETENCY_STATUS } from '../constants';

export const statusToIcon = {
  [COMPETENCY_STATUS.NONE]: null,
  [COMPETENCY_STATUS.MISSING_REQUIREMENTS]: MissingRequirements,
  [COMPETENCY_STATUS.EXPIRED]: Expired,
  [COMPETENCY_STATUS.EXPIRES_SOON]: ExpiresSoon,
  [COMPETENCY_STATUS.NOT_APPROVED]: NotApproved,
  [COMPETENCY_STATUS.REQUIRES_APPROVAL]: RequiresApproval,
  [COMPETENCY_STATUS.UP_TO_DATE]: UpToDate,
  [COMPETENCY_STATUS.REJECTED]: Rejected,
};

export const statusToTooltipText = {
  [COMPETENCY_STATUS.NONE]: 'None',
  [COMPETENCY_STATUS.MISSING_REQUIREMENTS]: 'Not completed',
  [COMPETENCY_STATUS.EXPIRED]: 'Expired',
  [COMPETENCY_STATUS.EXPIRES_SOON]: 'Expires soon',
  [COMPETENCY_STATUS.NOT_APPROVED]: 'Not approved',
  [COMPETENCY_STATUS.REQUIRES_APPROVAL]: 'Needs approval',
  [COMPETENCY_STATUS.UP_TO_DATE]: 'Up-to-date',
  [COMPETENCY_STATUS.REJECTED]: 'Rejected',
};

const CompetencyStatusIcon = ({
  status,
  IconProps,
  displayTooltipText,
  Icon = statusToIcon[status],
}) => {
  const tooltipText = displayTooltipText ? statusToTooltipText[status] : '';

  if (!Icon) return null;

  return (
    <SvgIcon color="secondary" titleAccess={tooltipText} {...IconProps}>
      <Icon />
    </SvgIcon>
  );
};

CompetencyStatusIcon.defaultProps = {
  displayTooltipText: true,
};

export default compose()(CompetencyStatusIcon);
