import React from 'react';
import { compose } from 'redux';
import { blue } from '@material-ui/core/colors';

const RequiresApproval = () => (
  <>
    <circle cx="12" cy="12" r="12" fill={blue[700]} />
    <circle cx="12" cy="5" r="2" />
    <path d="M10 9h4v12h-4z" />
  </>
);

export default compose(React.memo)(RequiresApproval);
