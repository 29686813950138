import { PublicClientApplication, LogLevel } from '@azure/msal-browser';

import { IN_DEV } from './constants';

const createAuthService = async (config, tokenReceivedCallback) => {
  const msalConfig = {
    auth: {
      clientId: config.clientId,
      authority: config.authority,
      redirectUri: config.redirectUrl,
      postLogoutRedirectUri: config.logoutUrl,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false, // Set to true if you are working with IE or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback(logLevel, message) {
          // ignore logging for now
        },
        logLevel: IN_DEV ? LogLevel.Verbose : LogLevel.Warning,
        piiLoggingEnabled: false,
      },
    },
  };

  // Handling authentication response
  const handleResponse = (msal, response) => {
    tokenReceivedCallback(msal, response);
  };

  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();

  // Handle redirect promise
  try {
    const response = await msalInstance.handleRedirectPromise();
    handleResponse(msalInstance, response);
  } catch (error) {
    console.error('Error handling auth redirect', error);
  }

  return msalInstance;
};

export default createAuthService;
