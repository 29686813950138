import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import MoreVert from '@material-ui/icons/MoreVert';
import { Menu, IconButton } from '@material-ui/core';

import { invokeIfFunction } from '../utils';

class DropdownMenu extends PureComponent {
  state = { anchorEl: null };

  toggleMenu = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  handleClick = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (event) => {
    const { onClose } = this.props;

    event.stopPropagation();
    invokeIfFunction(onClose, event);
    this.setState({ anchorEl: null });
  };

  render() {
    const { children, Trigger, onClick, MenuProps, multiple } = this.props;

    const { anchorEl } = this.state;

    return (
      <React.Fragment>
        {React.cloneElement(Trigger, {
          onClick: (event) => {
            this.handleClick(event);
            invokeIfFunction(onClick, event);
          },
        })}
        <Menu
          PaperProps={{
            style: {
              maxHeight: '50%',
            },
          }}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          {...MenuProps}
        >
          {React.Children.toArray(children).map((child) => {
            const { onClick } = child.props;

            return React.cloneElement(child, {
              onClick: (event) => {
                invokeIfFunction(onClick);

                if (!multiple) {
                  this.handleClose(event);
                }
              },
            });
          })}
        </Menu>
      </React.Fragment>
    );
  }
}

DropdownMenu.propTypes = {
  Trigger: PropTypes.element,
  multiple: PropTypes.bool,
};

DropdownMenu.defaultProps = {
  multiple: false,
  Trigger: (
    <IconButton>
      <MoreVert fontSize="small" />
    </IconButton>
  ),
};

export default DropdownMenu;
