import { IgcDockManagerResourceStrings } from '../components/dockmanager/dockmanager.public-interfaces';

/**
 * Japanese resource strings for the Dock Manager
 */
export const IgcDockManagerResourceStringsJP: IgcDockManagerResourceStrings = {
  close: '閉じる',
  pin: '固定',
  unpin: '固定解除',
  maximize: '最大化',
  minimize: '最小化',
  moreOptions: 'その他のオプション',
  moreTabs: 'その他のタブ',
  panes: 'Panes',
  documents: 'Documents'
};
