import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import React, { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import logo from '../assets/Altus_logo_White.svg';

class Logo extends PureComponent {
  render() {
    const { classes, rootUrl } = this.props;
    return (
      <Grid
        component={rootUrl ? Link : undefined}
        to={rootUrl ? rootUrl : undefined}
      >
        <img src={logo} alt="Altus Logo" className={classes.logo} />
      </Grid>
    );
  }
}

const styles = (theme) => ({
  logo: {
    display: 'flex',
    height: theme.spacing(3),
    paddingLeft: theme.spacing(1),
  },
});

export default compose(withStyles(styles, { name: 'Logo' }))(Logo);
