import isFunction from 'lodash/isFunction';

export const thunkMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (isFunction(action)) {
      return action(dispatch, (options = { toJS: false }) =>
        options.toJS ? getState().toJS() : getState(),
      );
    }
    return next(action);
  };

export default thunkMiddleware;
