import { ACTIONS } from './dataState.constants';

export const asyncStart = (type, params) => ({
  type: ACTIONS.ASYNC_START,
  subtype: type,
  ...params,
});

export const asyncEnd = (type, params) => ({
  type: ACTIONS.ASYNC_END,
  subtype: type,
  ...params,
});

export const asyncEndError = (type, params) => ({
  type: ACTIONS.ASYNC_END,
  subtype: type,
  error: true,
  ...params,
});
