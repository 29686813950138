import { connect } from 'react-redux';
import React, { PureComponent } from 'react';

import { isModalOpen, toggleModal } from 'altus-modal';

const withModal = (modalId) => (WrappedComponent) => {
  class Component extends PureComponent {
    constructor(props) {
      super(props);

      this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal = () => {
      const { dispatchToggleModal } = this.props;
      dispatchToggleModal({ modalId: modalId });
    };

    render() {
      const { Trigger } = this.props;

      return (
        <>
          {Trigger &&
            React.cloneElement(Trigger, {
              onClick: this.toggleModal,
            })}
          <WrappedComponent
            modalId={modalId}
            toggleModal={this.toggleModal}
            {...this.props}
          />
        </>
      );
    }
  }

  return connect(
    (state) => {
      return {
        open: isModalOpen(state, modalId),
      };
    },
    {
      dispatchToggleModal: toggleModal,
    },
  )(Component);
};

export default withModal;
