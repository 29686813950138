import { matchSorter } from 'match-sorter';
import { useState, useMemo, useCallback } from 'react';

const useTextSearch = (items, getItemLabel, initial = '') => {
  const [search, setSearch] = useState(initial);

  const matchingItems = useMemo(
    () =>
      matchSorter(items, search, {
        keys: [(item) => getItemLabel(item)],
      }),
    [items, search, getItemLabel],
  );

  const clearSearch = useCallback(
    () => setSearch(initial),
    [initial, setSearch],
  );

  return { search, setSearch, matchingItems, clearSearch };
};

export default useTextSearch;
