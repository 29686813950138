import { compose } from 'redux';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import React, { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Typography, Breadcrumbs } from '@material-ui/core';

import HeaderBase from '../HeaderBase';

class BreadcrumbHeader extends PureComponent {
  render() {
    const { classes, breadcrumbs } = this.props;

    return (
      <HeaderBase
        classes={{
          root: classes.header,
        }}
      >
        <Breadcrumbs>
          {breadcrumbs.map(({ match, breadcrumb, breadcrumbDisabled }) => {
            if (breadcrumbDisabled)
              return (
                <Typography
                  color="primary"
                  variant="caption"
                  key={breadcrumb.key}
                  className={classNames(
                    classes.breadcrumbText,
                    classes.breadcrumbDisabled,
                  )}
                >
                  {breadcrumb}
                </Typography>
              );

            return (
              <NavLink
                exact
                to={match.url}
                key={breadcrumb.key}
                activeClassName={classes.breadcrumbActive}
                onClick={() =>
                  match.url === window.location.pathname
                    ? window.location.reload()
                    : undefined
                }
              >
                <Typography
                  color="primary"
                  variant="caption"
                  className={classes.breadcrumbText}
                >
                  {breadcrumb}
                </Typography>
              </NavLink>
            );
          })}
        </Breadcrumbs>
      </HeaderBase>
    );
  }
}

const styles = (theme) => ({
  breadcrumbActive: {
    'height': theme.spacing(3),
    '& $breadcrumbText': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  breadcrumbText: {
    'display': 'flex',
    'padding': `${theme.spacing(1.5)}px 0`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbDisabled: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  header: {
    background: theme.palette.background.default,
  },
});

export default compose(
  withStyles(styles, {
    name: 'BreadcrumbHeader',
  }),
)(BreadcrumbHeader);
