import {
  IN_DEV,
  ACTIONS,
  HTTP_REASON,
  HTTP_STATUS,
  NOTIFICATION_VARIANTS,
} from '../constants';

import { invokeIfFunction } from '../utils';
import { getNotificationSelector } from './notification.selectors';
import { ACTIONS as NOTIFICATION_ACTIONS } from './notification.constants';
import { addNotification, removeNotification } from './notification.actions';

const createNotificationMiddleware =
  ({ onUnauthorized, onForbidden, onSiteAccessDenied }) =>
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const { error, type, notification } = action;

    if (error) {
      let errorMessage;
      let duration;
      switch (error.status) {
        case HTTP_STATUS.NOT_FOUND:
        case HTTP_STATUS.CONFLICT: {
          errorMessage = error.response.text;
          break;
        }
        case HTTP_STATUS.UNAUTHORIZED:
          invokeIfFunction(onUnauthorized);
          break;
        case HTTP_STATUS.FORBIDDEN: {
          if (
            action.payload &&
            action.payload.Reason === HTTP_REASON.SITE_ACCESS_DENIED
          ) {
            invokeIfFunction(onSiteAccessDenied);

            errorMessage =
              'Access denied - please contact a system administrator';
          } else {
            invokeIfFunction(onForbidden);
            errorMessage = 'Access denied';
          }
          break;
        }
        default: {
          if (notification?.[NOTIFICATION_VARIANTS.ERROR]) {
            errorMessage = action.notification[NOTIFICATION_VARIANTS.ERROR];
          } else if (error?.response?.body?.hasErrors) {
            errorMessage = error.response.body.errors[0];
            duration = 5000; // Make backend validation error messages last longer by default.
          } else {
            errorMessage = IN_DEV
              ? `ERROR: ${error.message}`
              : 'An error ocurred';
          }
          break;
        }
      }

      const errorVariantConfig =
        notification?.config?.[NOTIFICATION_VARIANTS.ERROR] || {};

      dispatch(
        addNotification({
          hideable: true,
          message: errorMessage,
          variant: NOTIFICATION_VARIANTS.ERROR,
          duration,
          ...errorVariantConfig,
        }),
      );

      return next(action);
    } else if (notification) {
      const currentNotification = getNotificationSelector(getState()).toJS();

      // Promise ends and it was successful.
      // Remove loading notification...
      if (currentNotification.variant === NOTIFICATION_VARIANTS.INFO) {
        dispatch(removeNotification());
      }

      // If the action being processed is the ADD_NOTIFICATION itself, ignore it.
      if (type === NOTIFICATION_ACTIONS.ADD_NOTIFICATION) {
        return next(action);
      }

      const variant = {
        [ACTIONS.ASYNC_START]: NOTIFICATION_VARIANTS.INFO,
        [ACTIONS.ASYNC_END]: NOTIFICATION_VARIANTS.SUCCESS,
      }[type];

      const variantMessage = notification[variant];
      const variantConfig = notification.config?.[variant] || {};

      // Add a notification for the corresponding variant only if there is a message defined for it.
      if (variantMessage) {
        dispatch(
          addNotification({
            force: true,
            variant,
            message: variantMessage,
            ...variantConfig,
          }),
        );
      }
    }

    return next(action);
  };

export default createNotificationMiddleware;
