import React from 'react';
import PropTypes from 'prop-types';

import { List, ListItem, Typography, ListItemText } from '@material-ui/core';

const MultiSelectModalGroup = ({ groupName, children }) => (
  <>
    <ListItem divider dense>
      <ListItemText>
        <Typography variant="h6">{groupName}</Typography>
      </ListItemText>
    </ListItem>
    <List>{children}</List>
  </>
);

MultiSelectModalGroup.propTypes = {
  groupName: PropTypes.string,
};

export default MultiSelectModalGroup;
