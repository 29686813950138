import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

const TIMER_MAX_SECONDS = 60;

const LoadingOverlayLinearProgress = ({
  classes,
  variant,
  ProgressComponent = CircularProgress,
  elapsedTimeLimit = TIMER_MAX_SECONDS,
}) => {
  const [elapsedSeconds, setElapsedSeconds] = useState(0);

  useEffect(() => {
    let intervalId;

    intervalId = setInterval(() => {
      setElapsedSeconds((currentElapsedSeconds) => currentElapsedSeconds + 0.5);
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const progress = Math.min(
    Math.round((elapsedSeconds * 100.0) / elapsedTimeLimit),
    100,
  );

  return (
    <ProgressComponent
      value={progress}
      variant={variant}
      color="secondary"
      className={classes.progress}
    />
  );
};

const styles = (theme) => ({
  progress: {
    width: '100%',
    height: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
});

export default withStyles(styles)(LoadingOverlayLinearProgress);
