import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import React, { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import BasePageHeader from './BasePageHeader';
import LoadingOverlay from '../LoadingOverlay';

class BasePage extends PureComponent {
  render() {
    const {
      Icon,
      title,
      classes,
      children,
      dataState,
      displayAddButton,
      addButtonOnClick,
      endHeaderText = '',
      LoadingOverlayProps,
      BasePageHeaderProps,
    } = this.props;

    return (
      <>
        <LoadingOverlay dataState={dataState} {...LoadingOverlayProps} />
        <Grid
          item
          xs
          container
          wrap="nowrap"
          direction="column"
          className={classes.root}
        >
          {(title || displayAddButton || addButtonOnClick) && (
            <BasePageHeader
              Icon={Icon}
              title={title}
              endText={endHeaderText}
              displayAddButton={displayAddButton}
              addButtonOnClick={addButtonOnClick}
              {...BasePageHeaderProps}
            />
          )}
          <Grid
            item
            xs
            container
            wrap="nowrap"
            className={classes.children}
            direction="column"
          >
            {children}
          </Grid>
        </Grid>
      </>
    );
  }
}

const styles = (theme) => ({
  root: {
    position: 'relative',
  },
  children: {
    padding: `0px ${theme.spacing(3)}px ${theme.spacing(2.25)}px`,
  },
});

export default compose(
  withStyles(styles, {
    name: 'BasePage',
  }),
)(BasePage);
