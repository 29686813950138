import React, { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Button, CircularProgress } from '@material-ui/core';

class LoadingButton extends PureComponent {
  render() {
    const { loading, children, classes, ...rest } = this.props;

    return (
      <Button disabled={loading} {...rest}>
        {children}
        {loading && (
          <CircularProgress
            size={20}
            color="secondary"
            classes={{
              root: classes.circularProgress,
            }}
          />
        )}
      </Button>
    );
  }
}

const styles = (theme) => ({
  circularProgress: {
    marginLeft: theme.spacing(1),
  },
});

export default withStyles(styles, { name: 'LoadingButton' })(LoadingButton);
