import React from 'react';

import { Grid } from '@material-ui/core';

const TableBody = ({ getTableBodyProps, children, ...rest }) => (
  <Grid container direction="column" {...rest} {...getTableBodyProps()}>
    {children}
  </Grid>
);

export default TableBody;
