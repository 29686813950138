import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import {
  Grid,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import {
  confirmationSelector,
  CONFIRMATION_DIALOG_MODAL_ID,
} from 'altus-redux-middlewares';

import { isModalOpen } from 'altus-modal';

import { invokeIfFunction } from '../utils';

class ConfirmationDialog extends PureComponent {
  render() {
    const { open, confirmation, classes } = this.props;

    const { title, onCancel, onConfirm, description, confirmButtonText } =
      confirmation;

    return (
      <Dialog fullWidth open={open} maxWidth="sm" onClose={onCancel}>
        <DialogTitle
          classes={{
            root: classes.dialogTitleRoot,
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography color="secondary" variant="subtitle2">
              {title}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.dialogContentRoot,
          }}
        >
          <Typography>{description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={onCancel}
            action={(action) => action && invokeIfFunction(action.focusVisible)}
          >
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  dialogContentRoot: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  dialogTitleRoot: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.altus.background.header,
  },
  headerButton: {
    fontWeight: 700,
    textTransform: 'none',
    background: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
});

export default compose(
  connect((state) => ({
    confirmation: confirmationSelector(state),
    open: isModalOpen(state, CONFIRMATION_DIALOG_MODAL_ID),
  })),
  withStyles(styles),
)(ConfirmationDialog);
