import React from 'react';
import noop from 'lodash/noop';
import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { Chip, Grid, Typography } from '@material-ui/core';

import { invokeIfFunction } from '../utils';
import CompetencyStatusIcon from './CompetencyStatusIcon';
import { statusToTooltipText, statusToIcon } from './CompetencyStatusIcon';

const defaultRenderCompetencyStatusIcon = ({ Icon, status }) => (
  <CompetencyStatusIcon
    Icon={Icon}
    status={status}
    displayTooltipText={false}
  />
);

const CompetencyStatusChip = ({
  status,
  classes,
  ChipProps,
  renderCompetencyStatusIcon,
}) => {
  const statusDescription = statusToTooltipText[status];
  const statusIcon = statusToIcon[status];

  if (!statusIcon) {
    return null;
  }

  const Icon = invokeIfFunction(renderCompetencyStatusIcon, {
    status,
    Icon: statusIcon,
  });

  return (
    <Chip
      container
      wrap="nowrap"
      onDelete={noop} // To make deleteIcon visible
      component={Grid}
      clickable={false}
      deleteIcon={Icon}
      variant="outlined"
      justifyContent="space-between"
      title={statusDescription}
      classes={{
        root: classes.chipRoot,
        label: classes.chipLabel,
      }}
      label={
        <Typography variant="inherit" noWrap>
          {statusDescription}
        </Typography>
      }
      {...ChipProps}
    />
  );
};

const styles = (theme) => ({
  chipRoot: {
    'padding': theme.spacing(0.5),
    'width': 'auto',
    'maxWidth': '100%',
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  chipLabel: {
    overflow: 'hidden',
  },
});

CompetencyStatusChip.defaultProps = {
  renderCompetencyStatusIcon: defaultRenderCompetencyStatusIcon,
};

export default compose(React.memo, withStyles(styles))(CompetencyStatusChip);
