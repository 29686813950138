import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Typography, Grid, Fade } from '@material-ui/core';

import { isLoading, useVisibilityTimeout } from '../utils';
import LoadingOverlayLinearProgress from './LoadingOverlayLinearProgress';

const LoadingOverlay = ({
  classes,
  variant,
  dataState,
  timeout = 300,
  elapsedTimeLimit,
  ProgressComponent,
  text = 'Loading...',
  loading = isLoading(dataState),
}) => {
  const [visible] = useVisibilityTimeout(timeout);

  const displayContent = visible && loading;

  if (!displayContent) return null;

  return (
    <Fade in>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.root}
        id="id_loadingOverlay"
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container item xs={3} justifyContent="center">
            <LoadingOverlayLinearProgress
              variant={variant}
              elapsedTimeLimit={elapsedTimeLimit}
              ProgressComponent={ProgressComponent}
            />
          </Grid>
          {text && (
            <Grid item>
              <Typography color="inherit" variant="h4">
                {text}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fade>
  );
};

const styles = (theme) => ({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    zIndex: theme.zIndex.modal,
    background: 'rgba(0, 0, 0, 0.4)',
    color: theme.palette.common.white,
  },
});

export default withStyles(styles, { name: 'LoadingOverlay' })(LoadingOverlay);
