import { compose } from 'redux';
import PropTypes from 'prop-types';
import { castArray, isString } from 'lodash';
import { Route, Redirect, Switch } from 'react-router-dom';
import React, { createElement, PureComponent } from 'react';

import { Helmet } from '../Helmet';
import NoContentBasePage from './NoContentBasePage';

const MissingRoutePage = () => (
  <NoContentBasePage
    header="That page doesn't exist"
    description="Sorry, the page you were looking for could not be found"
  />
);

class RouteContainer extends PureComponent {
  render() {
    const {
      routes,
      RouteProps,
      MissingRouteComponent,
      breadcrumb: parentBreadcrumb,
    } = this.props;

    return (
      <Switch>
        {castArray(routes).map((subRoute) => {
          const {
            exact,
            path,
            redirectTo,
            breadcrumb: subRouteBreadcrumb,
            component,
          } = subRoute;

          if (redirectTo) {
            return (
              <Redirect key={path} from={path} to={redirectTo} {...subRoute} />
            );
          }

          return (
            <Route
              key={path}
              path={path}
              exact={exact}
              render={({ match, location }) => (
                <>
                  {!!subRouteBreadcrumb && isString(subRouteBreadcrumb) && (
                    <Helmet>
                      <title>{subRouteBreadcrumb}</title>
                    </Helmet>
                  )}
                  {!!component &&
                    createElement(component, {
                      parentBreadcrumb,
                      parentRoutes: routes,
                      breadcrumb: subRouteBreadcrumb,
                      pathname: location.pathname,
                      ...match.params,
                      ...subRoute,
                      ...RouteProps,
                    })}
                </>
              )}
            />
          );
        })}
        <Route
          render={({ match, location }) => (
            <MissingRouteComponent
              pathname={location.pathname}
              {...match.params}
              {...RouteProps}
            />
          )}
        />
      </Switch>
    );
  }
}

RouteContainer.defaultProps = {
  routes: [],
  MissingRouteComponent: MissingRoutePage,
};

RouteContainer.propTypes = {
  breadcrumb: PropTypes.string,
  MissingRouteComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.elementType,
  ]),
  routes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default compose()(RouteContainer);
