import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';

import { Grid, Chip, withStyles } from '@material-ui/core';

import { EMPTY_LIST } from '../constants';

const MultiSelectModalChips = ({
  getLabel,
  classes,
  onDelete,
  items = EMPTY_LIST,
}) => (
  <Grid container item spacing={1} className={classes.root}>
    {items
      .sortBy((item) => getLabel(item))
      .map((selectedItem, index) => (
        <Grid item key={index}>
          <Chip
            size="small"
            color="primary"
            label={getLabel(selectedItem)}
            onDelete={() => onDelete(selectedItem)}
          />
        </Grid>
      ))}
  </Grid>
);

const styles = (theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
  },
});

MultiSelectModalChips.propTypes = {
  getLabel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Iterable).isRequired,
};

export default compose(withStyles(styles))(MultiSelectModalChips);
