import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import React, { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import LoadingOverlay from '../LoadingOverlay';
import { isLoading, invokeIfFunction } from '../utils';

class ApplicationBaseContent extends PureComponent {
  render() {
    const {
      classes,
      dataState,
      children,
      renderTopContent,
      LoadingOverlayProps,
    } = this.props;

    return (
      <Grid container item xs direction="column" className={classes.root}>
        {invokeIfFunction(renderTopContent)}
        <Grid
          xs
          item
          container
          wrap="nowrap"
          direction="column"
          className={classes.scrollContainer}
        >
          <LoadingOverlay
            dataState={dataState}
            text="Loading application..."
            {...LoadingOverlayProps}
          />
          {!isLoading(dataState) && children}
        </Grid>
      </Grid>
    );
  }
}

const styles = () => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
  },
  scrollContainer: {
    overflow: 'auto',
  },
});

export default compose(withStyles(styles, { name: 'ApplicationBaseContent' }))(
  ApplicationBaseContent,
);
