import noop from 'lodash/noop';
import { compose } from 'redux';
import debounce from 'lodash/debounce';
import Add from '@material-ui/icons/Add';
import React, { PureComponent } from 'react';
import Remove from '@material-ui/icons/Remove';
import { Button, ButtonGroup } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

class IncrementDecrementButton extends PureComponent {
  constructor(props) {
    super(props);

    const { value, onChange, timeout } = props;

    this.state = {
      value,
    };

    this.onChangeDebounced = debounce(onChange, timeout);
  }

  componentDidUpdate(prevProps) {
    const { value: prevValue } = prevProps;
    const { value } = this.props;

    if (prevValue !== value) {
      this.setState({
        value,
      });
    }
  }

  onChange = (event, value) =>
    this.setState({ value }, this.onChangeDebounced(event, value));

  increment = (event) => {
    const { maxValue } = this.props;

    this.onChange(event, Math.min(maxValue, this.state.value + 1));
  };

  decrement = (event) => {
    const { minValue } = this.props;

    this.onChange(event, Math.max(minValue, this.state.value - 1));
  };

  render() {
    const {
      classes,
      minValue,
      maxValue,
      ButtonGroupProps,
      DecrementButtonProps,
      IncrementButtonProps,
    } = this.props;

    const { value } = this.state;

    return (
      <ButtonGroup size="small" variant="contained" {...ButtonGroupProps}>
        <Button
          onClick={this.decrement}
          disabled={value === minValue}
          {...DecrementButtonProps}
        >
          <Remove fontSize="small" />
        </Button>
        <Button disabled className={classes.value}>
          {value}
        </Button>
        <Button
          onClick={this.increment}
          disabled={value === maxValue}
          {...IncrementButtonProps}
        >
          <Add fontSize="small" />
        </Button>
      </ButtonGroup>
    );
  }
}

const styles = (theme) => ({
  value: {
    '&.Mui-disabled': {
      background: theme.palette.grey[100],
      color: theme.palette.text.secondary,
    },
  },
});

IncrementDecrementButton.defaultProps = {
  value: 0,
  onChange: noop,
  minValue: Number.MIN_SAFE_INTEGER,
  maxValue: Number.MAX_SAFE_INTEGER,
};

export default compose(
  withStyles(styles, { name: 'IncrementDecrementButton' }),
)(IncrementDecrementButton);
