import React from 'react';
import { compose } from 'redux';
import { red } from '@material-ui/core/colors';

const Expired = () => (
  <>
    <circle cx="12" cy="12" r="12" fill={red[700]} />
    <circle cx="12" cy="19" r="2" />
    <path d="M10 3h4v12h-4z" />
  </>
);

export default compose(React.memo)(Expired);
