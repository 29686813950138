export const ACTIONS = {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
};

export const NOTIFICATION_VARIANTS = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
};
