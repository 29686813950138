import isFunction from 'lodash/isFunction';

import { isPromise } from '../utils';
import { ACTIONS, IN_DEV } from '../constants';

const promiseMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const { payload, type, ...rest } = action;

    if (isFunction(action.payload)) {
      action.payload = action.payload();
    }

    if (isPromise(action.payload)) {
      dispatch({ type: ACTIONS.ASYNC_START, subtype: type, ...rest });

      const currentState = getState();
      const currentView = currentState.viewChangeCounter;
      const skipTracking = action.skipTracking;

      action.payload
        .then((result) => {
          if (!skipTracking && currentState.viewChangeCounter !== currentView) {
            return;
          }

          dispatch({ ...action, payload: result, skipTracking: true });

          dispatch({
            type: ACTIONS.ASYNC_END,
            subtype: type,
            payload: result,
            ...rest,
          });
        })
        .catch((error) => {
          const response = error.response || { body: null };

          if (!skipTracking && currentState.viewChangeCounter !== currentView) {
            return;
          }

          dispatch({
            ...action,
            error,
            payload: response.body,
            skipTracking: true,
          });

          // TODO Handle errors
          dispatch({
            type: ACTIONS.ASYNC_END,
            subtype: type,
            error,
            ...rest,
          });

          if (IN_DEV) {
            throw error;
          }
        });

      return;
    }

    next(action);
  };

export default promiseMiddleware;
