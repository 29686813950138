import screenfull from 'screenfull';
import { useCallback, useEffect, useState } from 'react';

const useFullscreen = (elementRef) => {
  const [isFullscreen, setFullscreen] = useState(screenfull.isFullscreen);

  const onChange = useCallback(
    () => setFullscreen(screenfull.isFullscreen),
    [],
  );

  const toggleFullscreen = useCallback(() => {
    if (!screenfull.isEnabled) return;

    if (elementRef) {
      const element = elementRef.current;

      if (!element) return;

      screenfull.toggle(element);
    } else {
      screenfull.toggle();
    }
  }, [elementRef]);

  useEffect(() => {
    screenfull.on('change', onChange);

    return () => {
      screenfull.exit();
      screenfull.off('change', onChange);
    };
  }, [onChange]);

  return [isFullscreen, toggleFullscreen];
};

export default useFullscreen;
