import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import React, { useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Fade, Checkbox } from '@material-ui/core';

import { TableRowAction } from '../Table';
import { invokeIfFunction } from '../utils';

const BaseTableRow = ({
  item,
  theme,
  classes,
  striped = true,
  children,
  component,
  onRowClick,
  isSelected,
  renderIcon,
  useRowSelect,
  isAnyRowSelected,
  getToggleRowSelectedProps,
  ...rest
}) => {
  const Icon = invokeIfFunction(renderIcon, item);
  const handleOnClick = useCallback(
    () => invokeIfFunction(onRowClick, item),
    [onRowClick, item],
  );

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      onClick={handleOnClick}
      className={classNames(
        classes.root,
        classes.rootBackground,
        classes.rootBorder,
        {
          [classes.rootStriped]: striped,
          [classes.rootSelected]: isSelected,
          [classes.rootClickable]: !!onRowClick,
          [classes.rootSelectItem]: useRowSelect,
          [classes.rootSelectable]:
            useRowSelect && (isAnyRowSelected || isSelected),
        },
      )}
      {...rest}
    >
      {Icon && (
        <Grid item className={classes.icon}>
          <TableRowAction transparent>{Icon}</TableRowAction>
        </Grid>
      )}
      {useRowSelect && (
        <Grid item className={classes.selectIcon}>
          <TableRowAction transparent>
            <Checkbox
              {...getToggleRowSelectedProps()}
              title="Select"
              color="primary"
              classes={{
                colorSecondary: classes.checkbox,
              }}
            />
          </TableRowAction>
        </Grid>
      )}
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        component={component}
        className={classes.root}
      >
        {children}
      </Grid>
    </Grid>
  );
};

const TableRow = ({ fadeIn = true, getRowProps, offsetPx, ...rest }) => {
  if (fadeIn && isFunction(getRowProps)) {
    return (
      <Fade
        in
        {...getRowProps()}
        style={{
          marginLeft: offsetPx,
          width: `calc(100% - ${offsetPx}px)`,
        }}
      >
        <BaseTableRow {...rest} />
      </Fade>
    );
  }

  return <BaseTableRow {...rest} />;
};

TableRow.propTypes = {
  fadeIn: PropTypes.bool,
  striped: PropTypes.bool,
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  onRowClick: PropTypes.func,
  renderIcon: PropTypes.func,
  useRowSelect: PropTypes.bool,
  isAnyRowSelected: PropTypes.bool,
  getToggleRowSelectedProps: PropTypes.func,
};

const styles = (theme) => ({
  root: {
    textAlign: 'start',
    overflow: 'hidden',
    height: theme.altus.components.SortableListRow.height,
  },
  rootBackground: {
    backgroundColor: theme.altus.components.SortableListRow.background,
  },
  rootStriped: {
    '&:nth-child(even)': {
      backgroundColor: theme.altus.components.SortableListRow.stripedColor,
    },
  },
  rootSelected: {
    'backgroundColor': theme.altus.components.SortableListRow.selectedColor,
    '&:nth-child(even)': {
      backgroundColor: theme.altus.components.SortableListRow.selectedColor,
    },
    '& $selectIcon': {
      display: 'inherit',
    },
    '& $icon': {
      display: 'none',
    },
  },
  rootClickable: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.altus.components.SortableListRow.selectedColor,
    },
  },
  rootSelectable: {
    '& $selectIcon': {
      display: 'inherit',
    },
    '& $icon': {
      display: 'none',
    },
  },
  rootBorder: {
    borderWidth: 1,
    borderColor: theme.altus.components.SortableListRow.borderColor,
    borderStyle: 'none solid solid solid',
  },
  icon: {},
  selectIcon: { display: 'none' },
  rootSelectItem: {
    '&:hover $selectIcon': {
      display: 'inherit',
    },
    '&:hover $icon': {
      display: 'none',
    },
  },
  checkbox: {
    color: theme.altus.components.SortableListRow.checkboxColor,
  },
});

export default compose(
  React.memo,
  withStyles(styles, {
    name: 'TableRow',
  }),
)(TableRow);
