import React from 'react';
import PropTypes from 'prop-types';

const TabsContent = ({ value, children }) =>
  React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    const isSelected = child.props.value === value;

    if (!isSelected) return null;

    return React.cloneElement(child);
  });

TabsContent.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TabsContent;
