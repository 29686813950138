import { compose } from 'redux';
import PropTypes from 'prop-types';
import Add from '@material-ui/icons/Add';
import React, { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, Fab } from '@material-ui/core';

class MenuHeader extends PureComponent {
  render() {
    const { title, onAdd, classes } = this.props;

    return (
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        classes={{
          root: classes.root,
        }}
      >
        <Typography variant="h6">{title}</Typography>
        {!!onAdd && (
          <Fab title="Add" onClick={onAdd} color="secondary" size="small">
            <Add fontSize="small" />
          </Fab>
        )}
      </Grid>
    );
  }
}

MenuHeader.propTypes = {
  onAdd: PropTypes.func,
};

const styles = (theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    paddingRight: theme.spacing(1),
  },
});

export default compose(
  withStyles(styles, {
    name: 'MenuHeader',
  }),
)(MenuHeader);
