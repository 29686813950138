import React from 'react';
import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, Paper } from '@material-ui/core';

const AppFailure = ({ classes, errorMessage }) => (
  <Grid container item xs alignItems="center">
    <Grid item container justifyContent="center" alignItems="center">
      <Grid item xs={8}>
        <Paper
          classes={{
            root: classes.paperRoot,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Oops, something went wrong:</Typography>
            </Grid>
            {!!errorMessage && (
              <Grid item xs={12}>
                <Typography paragraph color="error" variant="caption">
                  <i>{errorMessage}</i>
                </Typography>
              </Grid>
            )}
            {!errorMessage && (
              <Grid item xs={12}>
                <Typography variant="body2">Sorry ...</Typography>
                <Typography variant="body2">It's not you.</Typography>
                <Typography variant="body2">It's us</Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </Grid>
);

const styles = (theme) => ({
  paperRoot: {
    padding: theme.spacing(2),
  },
});

export default compose(withStyles(styles, { name: 'AppFailure' }))(AppFailure);
