import { compose } from 'redux';
import { Map } from 'immutable';
import times from 'lodash/times';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isString from 'lodash/isString';
import React, { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Typography, Grid, Fade } from '@material-ui/core';

import { invokeIfFunction, isLoading } from '../utils';

import {
  SORT_DIRECTION,
  EMPTY_SET,
  EMPTY_MAP,
  RECEIVED_DATA_STATE,
} from '../constants';

import SortableListRow from './SortableListRow';
import SortableListHeader from './SortableListHeader';
import SortableListRowIcon from './SortableListRowIcon';
import SortableListRowColumn from './SortableListRowColumn';
import SortableListActionsHeader from './SortableListActionsHeader';

class SortableList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      sortDirection: props.defaultSortDirection,
      sortedColumnNo: props.defaultSortColumn,
      selectedItems: EMPTY_SET,
      filter: this.createDefaultFilter(props.columns),
      search: '',
    };
  }

  createDefaultFilter = (columns) =>
    Map(
      columns.map(({ filter }, index) => [
        index.toString(),
        filter && filter.defaultValue,
      ]),
    ).filter(Boolean);

  componentDidUpdate(prevProps) {
    const { items, getKey } = this.props;
    const { selectedItems } = this.state;

    if (prevProps.items !== items) {
      const keys = items.map(getKey);

      this.setState({
        selectedItems: selectedItems.filter((key) => keys.includes(key)),
      });
    }
  }

  setSearch = (search) =>
    this.setState({
      search,
    });

  setFilter = (columnNo, value) => {
    this.setState({
      filter: isNil(value)
        ? this.state.filter.delete(columnNo.toString())
        : this.state.filter.set(columnNo.toString(), value),
    });
  };

  clearFilter = () =>
    this.setState({
      filter: EMPTY_MAP,
    });

  renderItem = (item) => {
    const {
      Icon,
      getKey,
      actions,
      columns,
      noborder,
      selectable,
      onRowClick,
      renderIcon,
      toggleEditModal,
      createSortableListRowProps,
    } = this.props;

    const { selectedItems } = this.state;

    const icon =
      Icon || renderIcon ? (
        <SortableListRowIcon>
          {renderIcon ? renderIcon(item) : <Icon color="primary" />}
        </SortableListRowIcon>
      ) : undefined;

    const selectCurrentItem = selectable
      ? () => this.selectItem(item)
      : undefined;

    const key = getKey(item);

    return (
      <Fade in={true} key={key}>
        <SortableListRow
          noborder={noborder}
          selectItem={selectCurrentItem}
          selectable={selectable}
          listHasSelectedItems={!!selectedItems.size}
          selected={selectedItems ? selectedItems.has(key) : false}
          onClick={
            onRowClick ? () => onRowClick(item, toggleEditModal) : onRowClick
          }
          Icon={icon}
          Action={
            actions &&
            actions.map((action, index) => {
              const { getValue, ...rest } = action;

              return (
                <SortableListRowIcon key={index} transparent {...rest}>
                  {invokeIfFunction(getValue, item)}
                </SortableListRowIcon>
              );
            })
          }
          {...invokeIfFunction(createSortableListRowProps, item)}
        >
          {columns.map((column, index) => {
            const {
              getSortProperty,
              getValue,
              xs,
              filter,
              visible = true,
              ...rest
            } = column;

            return (
              <SortableListRowColumn key={index} item xs={xs} {...rest}>
                {visible && (
                  <>
                    {!getValue && invokeIfFunction(getSortProperty, item)}
                    {invokeIfFunction(getValue, item)}
                  </>
                )}
              </SortableListRowColumn>
            );
          })}
        </SortableListRow>
      </Fade>
    );
  };

  selectItem = (item) => {
    const { getKey } = this.props;
    const key = getKey(item);

    this.setState({
      selectedItems: this.state.selectedItems.has(key)
        ? this.state.selectedItems.delete(key)
        : this.state.selectedItems.add(key),
    });
  };

  deselectItems = (keys) => {
    this.setState({
      selectedItems: this.state.selectedItems.subtract(keys),
    });
  };

  selectAll = () => {
    if (!this.state.selectedItems.size) {
      this.setState({
        selectedItems: this.props.items
          .filter(this.filterItem)
          .map((item) => {
            const { getKey } = this.props;

            return getKey(item);
          })
          .toSet(),
      });
    } else {
      this.setState({
        selectedItems: EMPTY_SET,
      });
    }
  };

  setSortColumn = (columnNo) => {
    const { sortDirection, sortedColumnNo } = this.state;

    this.setState({
      sortedColumnNo: columnNo,
      sortDirection:
        sortedColumnNo === columnNo
          ? sortDirection === SORT_DIRECTION.ASC
            ? SORT_DIRECTION.DESC
            : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
    });
  };

  order = (items) => {
    const { columns } = this.props;
    const { sortDirection, sortedColumnNo } = this.state;

    if (!columns[sortedColumnNo]) {
      return items;
    }

    const { getSortProperty } = columns[sortedColumnNo];

    if (getSortProperty) {
      const sortedItems = items.sortBy((item) => {
        const property = getSortProperty(item);

        return property
          ? isString(property)
            ? property.toLowerCase()
            : property
          : '';
      });

      return sortDirection === SORT_DIRECTION.ASC
        ? sortedItems
        : sortedItems.reverse();
    }

    return items;
  };

  itemMatchesFilter = (item) => {
    const { columns } = this.props;
    const { filter } = this.state;

    return filter.size
      ? filter.every((value, columnNo) => {
          const { getSortProperty } = columns[columnNo];
          const itemValue = getSortProperty ? getSortProperty(item) : undefined;

          return value === itemValue;
        })
      : true;
  };

  itemMatchesSearch = (item) => {
    const { columns } = this.props;
    const { search } = this.state;

    return search
      ? columns
          .map(({ getSortProperty }) => invokeIfFunction(getSortProperty, item))
          .filter(Boolean)
          .some((value) =>
            value
              .toString()
              .toLowerCase()
              .includes(search.trim().toLowerCase()),
          )
      : true;
  };

  filterItem = (item) =>
    this.itemMatchesFilter(item) && this.itemMatchesSearch(item);

  hasActionsHeader = () => {
    const { columns, onRefresh, listActions, title, displaySearchField } =
      this.props;

    return (
      columns.some(({ filter }) => filter) ||
      onRefresh ||
      !!listActions.length ||
      title ||
      displaySearchField
    );
  };

  render() {
    const {
      Icon,
      title,
      items,
      columns,
      classes,
      actions,
      noborder,
      minItems,
      noheader,
      onRefresh,
      dataState,
      selectable,
      renderIcon,
      listActions,
      stickyHeader,
      noResultsMessage,
      displaySearchField,
      displayNumberOfItems,
      SortableListHeaderProps,
      SortableListActionsHeaderProps,
    } = this.props;

    const { sortedColumnNo, selectedItems } = this.state;

    const sortedItems = this.order(items.filter(this.filterItem));

    return (
      <Grid container className={classes.root}>
        {this.hasActionsHeader() && (
          <SortableListActionsHeader
            title={title}
            items={items}
            columns={columns}
            onRefresh={onRefresh}
            selectable={selectable}
            listActions={listActions}
            search={this.state.search}
            setSearch={this.setSearch}
            filter={this.state.filter}
            setFilter={this.setFilter}
            selectItem={this.selectItem}
            selectedItems={selectedItems}
            clearFilter={this.clearFilter}
            deselectItems={this.deselectItems}
            displaySearchField={displaySearchField}
            {...SortableListActionsHeaderProps}
          />
        )}
        {!noheader && (
          <Grid
            container
            className={classNames({
              [classes.headerRootSticky]: stickyHeader,
            })}
          >
            <SortableListHeader
              items={items}
              noborder={noborder}
              actions={actions}
              columns={columns}
              selectable={selectable}
              hasIcon={!!Icon || !!renderIcon}
              selectAll={this.selectAll}
              selectedItems={selectedItems}
              sortedColumnNo={sortedColumnNo}
              setSortColumn={this.setSortColumn}
              sortDirection={this.state.sortDirection}
              {...SortableListHeaderProps}
            />
          </Grid>
        )}
        {!items.size && !isLoading(dataState) && (
          <SortableListRow striped={false} noborder>
            <Grid container justifyContent="center">
              {isString(noResultsMessage) ? (
                <Typography variant="caption">{noResultsMessage}</Typography>
              ) : (
                { noResultsMessage }
              )}
            </Grid>
          </SortableListRow>
        )}
        {!!items.size && !sortedItems.size && (
          <SortableListRow striped={false} noborder>
            <Grid container justifyContent="center">
              <Typography variant="caption">No matches...</Typography>
            </Grid>
          </SortableListRow>
        )}
        {!isLoading(dataState) && sortedItems.map(this.renderItem)}
        {displayNumberOfItems && !!sortedItems.size && (
          <SortableListRow striped={false} noborder>
            <Grid container justifyContent="flex-end">
              <Typography variant="caption">
                {`${sortedItems.size} items(s)`}
              </Typography>
            </Grid>
          </SortableListRow>
        )}
        {/* Reserve space for 'minItems' items */}
        {times(minItems - sortedItems.size, (index) => (
          <SortableListRow
            key={index}
            style={{
              visibility: 'hidden',
            }}
          />
        ))}
      </Grid>
    );
  }
}

SortableList.defaultProps = {
  minItems: 0,
  listActions: [],
  stickyHeader: true,
  defaultSortColumn: 0,
  displayNumberOfItems: false,
  dataState: RECEIVED_DATA_STATE,
  defaultSortDirection: SORT_DIRECTION.ASC,
  noResultsMessage: 'No items..',
};

SortableList.propTypes = {
  noborder: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      getSortProperty: PropTypes.func,
    }),
  ),
};

const styles = () => ({
  root: {},
  headerRootSticky: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
});

export default compose(withStyles(styles, { name: 'SortableList' }))(
  SortableList,
);
