import { Avatar } from '@material-ui/core';
import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { getAvatar } from '../utils';

const UserAvatar = ({ avatar, initials, FallbackIcon, ...rest }) => {
  return (
    <Avatar src={getAvatar(avatar)} {...rest}>
      {avatar || initials || <FallbackIcon />}
    </Avatar>
  );
};

UserAvatar.defaultProps = {
  FallbackIcon: AccountCircle,
};

export default UserAvatar;
