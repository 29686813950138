import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { Grid, Chip } from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';
import withStyles from '@material-ui/styles/withStyles';

export const TILE_CHIP_TYPES = {
  HIDDEN: 'hidden',
  VISIBLE: 'visible',
  NOTIFICATION: 'notification',
};

class Tile extends PureComponent {
  render() {
    const { Icon, classes, chipType, children, chipLabel } = this.props;

    return (
      <Grid item container className={classes.root}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          {Icon && (
            <Grid item>
              <Icon
                color="primary"
                classes={{
                  root: classes.iconRoot,
                }}
              />
            </Grid>
          )}
          {children && (
            <Grid item container justifyContent="center" alignItems="center">
              {children}
            </Grid>
          )}
          <Grid item className={classes[`chipContainer-${chipType}`]}>
            <Chip
              variant="outlined"
              label={chipLabel}
              classes={{
                root: classNames(classes.chip, classes[`chip-${chipType}`]),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  root: {
    height: 275,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
    borderRadius: theme.spacing(0.2),
    background: theme.palette.common.white,
  },
  iconRoot: {
    width: 60,
    height: 60,
  },
  chip: {
    fontWeight: 700,
  },
  [`chipContainer-${TILE_CHIP_TYPES.HIDDEN}`]: {
    display: 'none',
  },
  [`chip-${TILE_CHIP_TYPES.NOTIFICATION}`]: {
    color: theme.palette.primary.contrastText,
    backgroundColor: deepOrange[900],
    border: 'none',
  },
});

Tile.defaultProps = {
  chipLabel: '...',
  chipType: TILE_CHIP_TYPES.HIDDEN,
};

Tile.propTypes = {
  chipType: PropTypes.oneOf(Object.values(TILE_CHIP_TYPES)).isRequired,
  chipLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default compose(withStyles(styles, { name: 'Tile' }))(Tile);
