import React from 'react';
import { IconButton } from '@material-ui/core';
import { sortableHandle } from 'react-sortable-hoc';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import { TableRowActionsCell } from '.';

const DragHandle = ({ Icon, fontSize, ...rest }) => (
  <TableRowActionsCell>
    <IconButton title="Move" {...rest}>
      <Icon fontSize={fontSize} />
    </IconButton>
  </TableRowActionsCell>
);

DragHandle.defaultProps = {
  fontSize: 'medium',
  Icon: DragHandleIcon,
};

export default sortableHandle(DragHandle);
