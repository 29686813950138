import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { Grid, Checkbox } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import SortableListRowIcon from './SortableListRowIcon';

const SortableListRow = ({
  classes,
  children,
  striped,
  selected,
  onClick,
  to,
  href,
  noborder,
  Icon,
  StatusIcon = null,
  Action,
  component,
  selectable,
  selectItem,
  target,
  listHasSelectedItems,
  ...rest
}) => {
  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      className={classNames(classes.root, classes.rootBackground, {
        [classes.rootSelected]: selected,
        [classes.rootStriped]: striped,
        [classes.rootClickable]: onClick || to || href,
        [classes.rootBorder]: !noborder,
        [classes.rootSelectable]: !!Icon ? listHasSelectedItems : selectable,
        [classes.rootSelectItem]: selectItem,
      })}
      {...rest}
    >
      <Grid item className={classes.icon}>
        {Icon}
      </Grid>
      <Grid item className={classes.selectIcon}>
        <SortableListRowIcon transparent>
          <Checkbox
            classes={{
              colorSecondary: classes.checkbox,
            }}
            color="primary"
            checked={selected}
            onClick={selectItem}
          />
        </SortableListRowIcon>
      </Grid>

      <Grid
        to={to}
        container
        href={href}
        target={target}
        onClick={onClick}
        alignItems="center"
        component={component}
        className={classes.root}
      >
        {children}
      </Grid>
      {StatusIcon}
      {Action}
    </Grid>
  );
};

SortableListRow.defaultProps = {
  striped: true,
  selectable: false,
};

const styles = (theme) => ({
  root: {
    textAlign: 'start',
    overflow: 'hidden',
    height: theme.altus.components.SortableListRow.height,
  },
  rootBackground: {
    backgroundColor: theme.altus.components.SortableListRow.background,
  },
  rootStriped: {
    '&:nth-child(even)': {
      backgroundColor: theme.altus.components.SortableListRow.stripedColor,
    },
  },
  rootSelected: {
    'backgroundColor': theme.altus.components.SortableListRow.selectedColor,
    '&:nth-child(even)': {
      backgroundColor: theme.altus.components.SortableListRow.selectedColor,
    },
    '& $selectIcon': {
      display: 'inherit',
    },
    '& $icon': {
      display: 'none',
    },
  },
  rootClickable: {
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: theme.altus.components.SortableListRow.clickableColor,
    },
    '&:hover $rootStriped': {
      backgroundColor: theme.altus.components.SortableListRow.clickableColor,
    },
  },
  rootSelectable: {
    '& $selectIcon': {
      display: 'inherit',
    },
    '& $icon': {
      display: 'none',
    },
  },
  rootIcon: {
    '& $selectIcon': {
      display: 'none',
    },
    '& $icon': {
      display: 'inherit',
    },
  },
  rootBorder: {
    borderWidth: 1,
    borderColor: theme.altus.components.SortableListRow.borderColor,
    borderStyle: 'none solid solid solid',
  },
  icon: {},
  selectIcon: { display: 'none' },
  rootSelectItem: {
    '&:hover $selectIcon': {
      display: 'inherit',
    },
    '&:hover $icon': {
      display: 'none',
    },
  },
  checkbox: {
    color: theme.altus.components.SortableListRow.checkboxColor,
  },
});

export default compose(
  withStyles(styles, {
    name: 'SortableListRow',
  }),
)(SortableListRow);
