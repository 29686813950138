import React from 'react';
import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Grid, Button, Typography } from '@material-ui/core';

const CalendarNavigation = ({ classes, year, disabled, changeYear }) => {
  return (
    <Grid
      container
      spacing={5}
      justifyContent="center"
      alignItems="center"
      className={classes.navigationContainer}
    >
      {!disabled && (
        <Grid item>
          <Button
            className={classes.selectYearBtn}
            onClick={() => changeYear(year - 1)}
          >
            <ChevronLeft fontSize="small" className={classes.leftIcon} />
            <Typography variant="caption">{year - 1}</Typography>
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          disabled
          color="default"
          variant="contained"
          className={classes.selectedYear}
        >
          {year}
        </Button>
      </Grid>
      {!disabled && (
        <Grid item>
          <Button
            className={classes.selectYearBtn}
            onClick={() => changeYear(year + 1)}
          >
            <Typography variant="caption">{year + 1}</Typography>
            <ChevronRight fontSize="small" className={classes.rightIcon} />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const styles = (theme) => ({
  navigationContainer: {
    paddingBottom: theme.spacing(1),
  },
  selectedYear: {
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
  selectYearBtn: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

export default compose(
  withStyles(styles, { name: 'CalendarNavigation' }),
  React.memo,
)(CalendarNavigation);
