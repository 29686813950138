import PropTypes from 'prop-types';
import matchSorter from 'match-sorter';
import React, { PureComponent } from 'react';
import { Chip, TextField } from '@material-ui/core';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';

import { EMPTY_LIST } from '../constants';

class Autocomplete extends PureComponent {
  renderTags = (value, getTagProps) => {
    const { getOptionLabel, ChipProps } = this.props;

    return value.map((option, index) => (
      <Chip
        label={getOptionLabel(option)}
        {...getTagProps({ index })}
        {...ChipProps}
      />
    ));
  };

  renderInput = (params) => {
    const { TextFieldProps } = this.props;

    return <TextField {...params} {...TextFieldProps} />;
  };

  filterOptions = (options, { inputValue }) => {
    const { getOptionLabel } = this.props;

    return matchSorter(options, inputValue, {
      keys: [(item) => getOptionLabel(item)],
      threshold: matchSorter.rankings.CONTAINS,
    });
  };

  render() {
    const {
      options,
      ChipProps,
      TextFieldProps,
      getOptionLabel,
      renderTags = this.renderTags,
      renderInput = this.renderInput,
      filterOptions = this.filterOptions,
      ...rest
    } = this.props;

    const optionsArray = options.toList().toArray();

    return (
      <MuiAutocomplete
        size="small"
        options={optionsArray}
        renderTags={renderTags}
        renderInput={renderInput}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        {...rest}
      />
    );
  }
}

Autocomplete.defaultProps = {
  popupIcon: null,
  openOnFocus: true,
  options: EMPTY_LIST,
  disableCloseOnSelect: true,
  filterSelectedOptions: true,
};

Autocomplete.propTypes = {
  options: PropTypes.object.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
};

export default Autocomplete;
