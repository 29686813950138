import { compose } from 'redux';
import React, { PureComponent } from 'react';
import { blueGrey } from '@material-ui/core/colors';
import { Tooltip, IconButton } from '@material-ui/core';

import withStyles from '@material-ui/styles/withStyles';

class ReportHeaderIconButton extends PureComponent {
  render() {
    const { Icon, title, classes, ...rest } = this.props;

    return (
      <Tooltip title={title}>
        <IconButton
          variant="contained"
          classes={{
            root: classes.icon,
          }}
          {...rest}
        >
          <Icon />
        </IconButton>
      </Tooltip>
    );
  }
}

const styles = (theme) => ({
  icon: {
    'backgroundColor': theme.palette.grey[300],
    'padding': theme.spacing(1),
    '&:hover': {
      background: blueGrey[100],
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[300],
    },
  },
});

export default compose(withStyles(styles, { name: 'ReportHeaderIconButton' }))(
  ReportHeaderIconButton,
);
