import moment from 'moment';
import { compose } from 'redux';
import { List } from 'immutable';
import { Grid } from '@material-ui/core';
import React, { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import CalendarDay from './CalendarDay';
import { EMPTY_LIST } from '../constants';
import CalendarRange from './CalendarRange';
import CalendarPlaceholderDay from './CalendarPlaceholderDay';

const dateIsWithinDateRange = (date, startDate, endDate) =>
  date.isBetween(startDate, endDate, 'day', '[]');

const getMaxEndDateFromRange = (dateRanges) =>
  dateRanges
    .map((dateRange) => dateRange.get('endDate'))
    .max()
    .clone()
    .endOf('day');

const getAllDaysInMonth = (year, month, maxNumberOfDays) => {
  const startOfIsoWeek = moment({ month, year })
    .startOf('month')
    .startOf('isoWeek');

  const range = moment.rangeFromInterval(
    'day',
    maxNumberOfDays,
    startOfIsoWeek,
  );

  return List(range.by('day', { excludeEnd: true }));
};

const isSelected = (date, selectedStartDate, selectedEndDate) =>
  selectedStartDate && selectedEndDate
    ? dateIsWithinDateRange(date, selectedStartDate, selectedEndDate)
    : false;

const getMonthDatesAndRanges = (allDatesInMonth, dateRanges) => {
  return allDatesInMonth.reduce(
    ([dates, startDates], date) => {
      const matchingDateRanges = dateRanges.filter((dateRange) =>
        dateIsWithinDateRange(
          date,
          dateRange.get('startDate'),
          dateRange.get('endDate'),
        ),
      );

      if (matchingDateRanges.size) {
        if (!dates.takeLast(1).includes(matchingDateRanges)) {
          return [dates.push(matchingDateRanges), startDates.push(date)];
        } else {
          return [dates, startDates];
        }
      }

      return [dates.push(date), startDates];
    },
    [EMPTY_LIST, EMPTY_LIST],
  );
};

class CalendarMonth extends PureComponent {
  constructor(props) {
    const { year, month, maxNumberOfDays } = props;
    super(props);

    this.state = {
      allDatesInMonth: getAllDaysInMonth(year, month, maxNumberOfDays),
    };
  }

  componentDidUpdate(prevProps) {
    const { year, month, maxNumberOfDays } = this.props;

    if (prevProps.year !== year) {
      this.setState({
        allDatesInMonth: getAllDaysInMonth(year, month, maxNumberOfDays),
      });
    }
  }

  render() {
    const {
      month,
      classes,
      renderDay,
      dateRanges,
      DayComponent,
      onSelectDates,
      renderRangeEnd,
      selectedEndDate,
      maxNumberOfDays,
      setSelectedRange,
      renderRangeStart,
      DateRangeComponent,
      selectedStartDate,
      DateRangeEndComponent,
      DateRangeStartComponent,
      DateRangeComponentProps,
    } = this.props;

    const [monthDayDates, startDates] = getMonthDatesAndRanges(
      this.state.allDatesInMonth,
      dateRanges,
    );

    let rangeStartDates = startDates;

    const dayWidth = `${100 / maxNumberOfDays}%`;

    return (
      <Grid container key={month} wrap="nowrap" className={classes.calendarRow}>
        {monthDayDates.map((dateOrRange, index) => {
          if (moment.isMoment(dateOrRange)) {
            return dateOrRange.month() === month ? (
              <CalendarDay
                key={index}
                width={dayWidth}
                date={dateOrRange}
                DayComponent={DayComponent}
                onSelectDates={onSelectDates}
                setSelectedRange={setSelectedRange}
                isSelected={isSelected(
                  dateOrRange,
                  selectedStartDate,
                  selectedEndDate,
                )}
              />
            ) : (
              <CalendarPlaceholderDay
                key={index}
                width={dayWidth}
                date={dateOrRange}
              />
            );
          }

          // mutate startDates
          const dateRangeStart = rangeStartDates.first();
          rangeStartDates = rangeStartDates.shift();
          const nextRangeStartDate = rangeStartDates.first();

          const maxEndDate = getMaxEndDateFromRange(dateOrRange);

          const dateRangeEnd = nextRangeStartDate
            ? maxEndDate.isSameOrAfter(nextRangeStartDate)
              ? nextRangeStartDate.clone().subtract(1, 'day')
              : maxEndDate
            : maxEndDate;

          return (
            <CalendarRange
              key={index}
              month={month}
              end={dateRangeEnd}
              dayWidth={dayWidth}
              renderDay={renderDay}
              start={dateRangeStart}
              dateRange={dateOrRange}
              DayComponent={DayComponent}
              onSelectDates={onSelectDates}
              renderRangeEnd={renderRangeEnd}
              selectedEndDate={selectedEndDate}
              setSelectedRange={setSelectedRange}
              renderRangeStart={renderRangeStart}
              selectedStartDate={selectedStartDate}
              DateRangeComponent={DateRangeComponent}
              availableDates={this.state.allDatesInMonth}
              DateRangeEndComponent={DateRangeEndComponent}
              DateRangeStartComponent={DateRangeStartComponent}
              DateRangeComponentProps={DateRangeComponentProps}
            />
          );
        })}
      </Grid>
    );
  }
}

const styles = (theme) => ({
  calendarRow: {
    height: theme.altus.mediumRow.height,
  },
});

export default compose(
  withStyles(styles, { name: 'CalendarMonth' }),
  React.memo,
)(CalendarMonth);
