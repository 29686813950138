import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

const TableCell = ({
  xs,
  index,
  classes,
  rowDepth,
  children,
  subrowOffset,
  ...rest
}) => (
  <Typography
    item
    noWrap
    xs={xs}
    variant="body2"
    component={Grid}
    className={classNames(classes.root, {
      [classes.xs]: isNumber(xs),
      [classes.offset]: !index && rowDepth,
    })}
    {...rest}
  >
    {children}
  </Typography>
);

const styles = (theme) => ({
  root: {
    padding: `0 ${theme.spacing(2)}px`,
    color: theme.altus.components.SortableListRowColumn.textColor,
    overflow: 'initial',
  },
  xs: {
    overflow: 'hidden',
  },
  offset: {
    marginLeft: ({ subrowOffset = 0, rowDepth }) =>
      -theme.spacing(subrowOffset) * rowDepth,
  },
});

export default compose(
  React.memo,
  withStyles(styles, { name: 'TableCell' }),
)(TableCell);
