import { compose } from 'redux';
import React, { PureComponent } from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

class HeaderBase extends PureComponent {
  render() {
    const { children, classes, disableGutters, ToolbarProps, ...rest } =
      this.props;

    return (
      <AppBar
        elevation={0}
        color="primary"
        position="relative"
        className={classes.root}
        {...rest}
      >
        <Toolbar
          variant="dense"
          disableGutters={disableGutters}
          {...ToolbarProps}
        >
          {children}
        </Toolbar>
      </AppBar>
    );
  }
}

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
});

export default compose(withStyles(styles, { name: 'HeaderBase' }))(HeaderBase);
