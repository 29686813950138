import React, { useMemo } from 'react';
import { compose } from 'redux';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { List as MuiList, withStyles, Typography } from '@material-ui/core';

import { EMPTY_LIST } from '../constants';
import { invokeIfFunction } from '../utils';
import MultiSelectModalItem from './MultiSelectModalItem';
import MultiSelectModalGroup from './MultiSelectModalGroup';

const MultiSelectModalList = ({
  getName,
  groupBy,
  isSelected,
  toggleSelect,
  getDescription,
  items = EMPTY_LIST,
}) => {
  const groupByFirstChar = useCallback(
    (item) => getName(item).charAt(0),
    [getName],
  );

  const groupedItems = useMemo(
    () => List(items).groupBy(groupBy ?? groupByFirstChar),
    [items, groupBy, groupByFirstChar],
  );

  if (!items.length) {
    return (
      <Typography display="block" align="center" variant="overline">
        <em>No matches</em>
      </Typography>
    );
  }

  return (
    <MuiList>
      {groupedItems.entrySeq().map(([group, groupItems]) => (
        <MultiSelectModalGroup key={group} groupName={group}>
          {groupItems.map((item, index) => (
            <MultiSelectModalItem
              key={index}
              name={getName(item)}
              checked={isSelected(item)}
              onClick={() => toggleSelect(item)}
              description={invokeIfFunction(getDescription, item)}
            />
          ))}
        </MultiSelectModalGroup>
      ))}
    </MuiList>
  );
};

const styles = (theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(1)}px`,
  },
});

MultiSelectModalList.propTypes = {
  groupBy: PropTypes.func,
  items: PropTypes.array.isRequired,
  getName: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  toggleSelect: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(MultiSelectModalList);
