import { useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Iterable } from 'immutable';
import React, { useRef } from 'react';

import {
  Grid,
  Button,
  Dialog,
  withStyles,
  DialogContent,
} from '@material-ui/core';

import { useTextSearch, useMultiselect } from 'altus-hooks';
import { ReceivedDataState, DataState } from 'altus-datastate';

import { EMPTY_LIST } from '../constants';
import SearchField from '../SearchField';
import LoadingButton from '../LoadingButton';
import { ModalActions, ModalHeader } from '../Modal';
import MultiSelectModalList from './MultiSelectModalList';
import MultiSelectModalChips from './MultiSelectModalChips';

const getSubmitLabelDefault = (selectedItems) =>
  selectedItems.size ? `Add (${selectedItems.size})` : 'Add';

const validateDefault = (selectedItems) => !!selectedItems.size;

const MultiSelectModal = ({
  open,
  title,
  groupBy,
  getName,
  onClose,
  classes,
  onSubmit,
  displayChips,
  getDescription,
  items = EMPTY_LIST,
  initialSelectedItems,
  validate = validateDefault,
  submitDataState = ReceivedDataState,
  getSubmitLabel = getSubmitLabelDefault,
}) => {
  const { selectedItems, toggleSelect, isSelected, clearSelection } =
    useMultiselect(initialSelectedItems);

  // TODO: expand useTextSearch to support multiple search keys (ie. name and description)
  const { search, setSearch, matchingItems, clearSearch } = useTextSearch(
    items,
    getName,
  );

  const inputRef = useRef(null);

  const handleOnExit = useCallback(() => {
    clearSearch();
    clearSelection();
  }, [clearSearch, clearSelection]);

  const isValid = useMemo(
    () => validate(selectedItems),
    [validate, selectedItems],
  );

  const isLoading = submitDataState.isLoading();

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onClose}
      TransitionProps={{
        onExit: handleOnExit,
      }}
    >
      <ModalHeader title={title} toggleModal={onClose} />
      <Grid
        container
        wrap="nowrap"
        direction="column"
        className={classes.rootContent}
      >
        <Grid container className={classes.filterContainer}>
          <SearchField
            autoFocus
            fullWidth
            value={search}
            onChange={setSearch}
            onClear={clearSearch}
            inputRef={inputRef}
            TextFieldProps={{
              autoFocus: true,
              fullWidth: true,
              variant: 'standard',
            }}
          />
          {displayChips && (
            <MultiSelectModalChips
              getLabel={getName}
              items={selectedItems}
              onDelete={(item) => toggleSelect(item)}
            />
          )}
        </Grid>
        <DialogContent>
          <MultiSelectModalList
            groupBy={groupBy}
            getName={getName}
            items={matchingItems}
            isSelected={isSelected}
            toggleSelect={toggleSelect}
            getDescription={getDescription}
          />
        </DialogContent>
      </Grid>
      <ModalActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          loading={isLoading}
          disabled={!isValid || isLoading}
          onClick={() => onSubmit(selectedItems)}
        >
          {getSubmitLabel(selectedItems)}
        </LoadingButton>
      </ModalActions>
    </Dialog>
  );
};

const styles = (theme) => ({
  rootContent: {
    height: '60vh',
  },
  filterContainer: {
    top: 0,
    zIndex: 2,
    marginTop: 0,
    position: 'sticky',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    background: theme.palette.secondary.dark,
  },
});

MultiSelectModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  groupBy: PropTypes.func,
  validate: PropTypes.func,
  displayChips: PropTypes.bool,
  getSubmitLabel: PropTypes.func,
  getDescription: PropTypes.func,
  getName: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Iterable).isRequired,
  submitDataState: PropTypes.instanceOf(DataState),
  initialSelectedItems: PropTypes.instanceOf(Iterable),
};

export default compose(withStyles(styles, { name: 'MultiSelectModal' }))(
  MultiSelectModal,
);
