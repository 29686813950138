import { createSelector } from 'reselect';

export const getModalFromState = createSelector(
  (state) => state.get('modal'),
  (modal) => modal,
);

export const getModalDirectionFromState = createSelector(
  getModalFromState,
  (modal) => modal.get('direction'),
);

export const getActiveModalIndexByModalId = createSelector(
  (state, modalId) =>
    state.getIn(['modal', 'openModals', modalId, 'activeIndex']),
  (activeIndex) => activeIndex,
);

export const isModalOpen = createSelector(
  (state, modalId) => !!state.getIn(['modal', 'openModals', modalId]),
  (open) => open,
);
