import React from 'react';
import { generatePath, useRouteMatch } from 'react-router-dom';

import Tabs from '../Tabs/Tabs';
import NavTab from '../Tabs/NavTab';

const NavTabsContainer = ({ routes, TabComponent, ...rest }) => {
  const routeMatch = useRouteMatch();

  const visibleRoutes = routes.filter((route) => !route.hidden);

  // Because we will determine the `selected` state manually, we set Tabs and NavTab `value` to null.
  // We cannot set them to undefined, or skip to pass it, as it will result in console warnings from Material-UI
  return (
    <Tabs value={null} {...rest}>
      {visibleRoutes.map((route) => {
        const { path, breadcrumb } = route;

        const url = generatePath(path, routeMatch.params);

        return (
          <NavTab
            to={url}
            key={path}
            value={null}
            route={route}
            label={breadcrumb}
            TabComponent={TabComponent}
          />
        );
      })}
    </Tabs>
  );
};

export default NavTabsContainer;
