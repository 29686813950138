import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { TableHeaderRow, TableHeaderCell } from '../Table';

const TableHeader = ({
  sticky,
  classes,
  renderIcon,
  headerGroups,
  useRowSelect,
  getToggleAllRowsSelectedProps,
  TableHeaderRowComponent = TableHeaderRow,
  TableHeaderCellComponent = TableHeaderCell,
}) => (
  <Grid
    container
    direction="column"
    className={classNames({
      [classes.sticky]: sticky,
    })}
  >
    {headerGroups.map((headerGroup, index) => {
      const { headers, getHeaderGroupProps } = headerGroup;
      const displayUseRowSelect =
        useRowSelect && index === headerGroups.length - 1;

      return (
        <TableHeaderRowComponent
          columns={headers}
          renderIcon={renderIcon}
          useRowSelect={displayUseRowSelect}
          TableHeaderCellComponent={TableHeaderCellComponent}
          getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps}
          {...getHeaderGroupProps()}
        />
      );
    })}
  </Grid>
);

const styles = () => ({
  sticky: {
    top: 0,
    zIndex: 2,
    position: 'sticky',
  },
});

TableHeader.propTypes = {
  renderIcon: PropTypes.func,
  useRowSelect: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  displayFooters: PropTypes.bool,
  getToggleAllRowsSelectedProps: PropTypes.func,
  headerGroups: PropTypes.arrayOf(PropTypes.shape({})),
};

export default compose(
  React.memo,
  withStyles(styles, { name: 'TableHeader' }),
)(TableHeader);
