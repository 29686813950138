import { useReducer } from 'react';

import useRequestWithDataState from './useRequestWithDataState';

const useReducerWithDataState = (request, ...withReducerArgs) => {
  const [requestWithDataState, dataState, clearDataState] =
    useRequestWithDataState(request);

  const [state, dispatch] = useReducer(...withReducerArgs);

  return [requestWithDataState, state, dispatch, dataState, clearDataState];
};

export default useReducerWithDataState;
