import { ACTIONS } from './notification.constants';

export const removeNotification = () => (dispatch) => {
  dispatch({ type: ACTIONS.REMOVE_NOTIFICATION });
};

export const addNotification =
  ({ message, duration, force, variant, ...rest }) =>
  (dispatch) => {
    dispatch({
      force,
      type: ACTIONS.ADD_NOTIFICATION,
      notification: {
        message,
        variant,
        duration,
        ...rest,
      },
    });
  };
