import React from 'react';
import { compose } from 'redux';
import isNil from 'lodash/isNil';
import ExpandMore from '@material-ui/icons/ExpandMore';
import withStyles from '@material-ui/styles/withStyles';

import {
  Grid,
  Button,
  MenuItem,
  Typography,
  Checkbox,
} from '@material-ui/core';

import DropdownMenu from '../DropdownMenu';

const FilterDropdown = ({
  title,
  label,
  items,
  value,
  classes,
  multiple,
  onChange,
  defaultText,
  ButtonProps,
  getItemValue,
  getFilterText,
  MenuItemProps,
  defaultEmptyValue,
  DropdownMenuProps,
  preItems,
  postItems,
  sort,
}) => {
  const allItems = items
    .map((item) => getItemValue(item))
    .toOrderedSet()
    .filter((value) => !isNil(value));

  const maybeSortedItems = sort ? allItems.sort() : allItems;

  return (
    <DropdownMenu
      multiple={multiple}
      Trigger={
        <Button
          size="small"
          color="default"
          variant="outlined"
          className={classes.filterButton}
          {...ButtonProps}
        >
          <Grid
            container
            spacing={1}
            wrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid
              item
              style={{
                overflow: 'hidden',
              }}
            >
              <Typography
                noWrap
                align="left"
                title={title}
                display="block"
                variant="caption"
                className={classes.filterLabel}
              >
                {label || title}
              </Typography>
              <Typography
                noWrap
                align="left"
                display="block"
                variant="inherit"
                title={value || defaultText}
              >
                {(getFilterText ? getFilterText(value) : value) || defaultText}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
              }}
            >
              <ExpandMore />
            </Grid>
          </Grid>
        </Button>
      }
      {...DropdownMenuProps}
    >
      <MenuItem
        selected={isNil(value)}
        onClick={() => onChange(defaultEmptyValue)}
        {...MenuItemProps}
      >
        {defaultText}
      </MenuItem>
      {preItems}
      {maybeSortedItems.map((val, index) => {
        const filterText = getFilterText ? getFilterText(val) : val;

        return (
          <MenuItem
            key={index}
            selected={value === val}
            onClick={() => onChange(val)}
            {...MenuItemProps}
          >
            <Grid container alignItems="center">
              {multiple && (
                <Grid item>
                  <Checkbox
                    color="primary"
                    checked={value ? value.includes(val.toString()) : false}
                  />
                </Grid>
              )}
              <Grid item>{filterText}</Grid>
            </Grid>
          </MenuItem>
        );
      })}
      {postItems}
    </DropdownMenu>
  );
};

FilterDropdown.defaultProps = {
  defaultEmptyValue: null,
  sort: true,
};

const styles = (theme) => ({
  filterButton: {
    maxWidth: 175,
    borderRadius: 2,
    textTransform: 'none',
    padding: `0 ${theme.spacing(1)}px`,
  },
  filterLabel: {
    color: theme.palette.text.secondary,
  },
});

export default compose(React.memo, withStyles(styles))(FilterDropdown);
