import { compose } from 'redux';
import React, { PureComponent } from 'react';
import { grey } from '@material-ui/core/colors';
import withStyles from '@material-ui/styles/withStyles';

import HeaderBase from '../HeaderBase';
import { LogoAndAppTitle } from '../LogoAndAppTitle';

class ReportHeaderBase extends PureComponent {
  render() {
    const { children, classes } = this.props;

    return (
      <HeaderBase
        classes={{
          root: classes.root,
        }}
      >
        <LogoAndAppTitle title="Report" to={null} />
        {children}
      </HeaderBase>
    );
  }
}

const styles = (theme) => ({
  root: {
    padding: `${theme.spacing(0.5)}px 0`,
    backgroundColor: grey[900],
  },
});

export default compose(withStyles(styles, { name: 'ReportHeaderBase' }))(
  ReportHeaderBase,
);
