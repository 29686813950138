import { ACTIONS } from './modal.constants';

export const toggleModal =
  ({ modalId, force = false, activeIndex, payload, ...extra }) =>
  (dispatch) => {
    dispatch({
      type: ACTIONS.TOGGLE_MODAL,
      modalId,
      force,
      payload,
      activeIndex,
      ...extra,
    });

    return payload;
  };
