import React from 'react';
import PropTypes from 'prop-types';
import FavoriteIcon from '@material-ui/icons/Star';
import yellow from '@material-ui/core/colors/yellow';
import FavoriteOutlineIcon from '@material-ui/icons/StarBorder';
import { Icon, IconButton, withStyles } from '@material-ui/core';

const FavoriteButton = ({
  onClick,
  classes,
  size = 'small',
  isFavorite = false,
  fontSize = 'small',
}) => (
  <IconButton size={size} className={classes.root} onClick={onClick}>
    <Icon
      component={isFavorite ? FavoriteIcon : FavoriteOutlineIcon}
      fontSize={fontSize}
    />
  </IconButton>
);

FavoriteButton.propTypes = {
  isFavorite: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  fontSize: PropTypes.oneOf(['medium', 'small', 'large']),
};

const styles = () => ({
  root: {
    color: yellow[700],
  },
});

export default withStyles(styles)(FavoriteButton);
