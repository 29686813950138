import { invokeIfFunction } from '../utils';
import { ACTIONS } from './confirmation.constants';

import { toggleConfirmationDialog } from './confirmation.actions';

const confirmationMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const { confirmationDialog } = action;

    if (!confirmationDialog) return next(action);

    const {
      onCancel,
      onConfirm,
      title = 'Are you sure?',
      confirmButtonText = 'Confirm',
      description = 'Are you sure you want to perform this action?',
    } = confirmationDialog;

    return new Promise((resolve, reject) => {
      dispatch({
        title,
        description,
        onCancel: reject,
        confirmButtonText,
        onConfirm: resolve,
        type: ACTIONS.CONFIRMATION_DIALOG_PENDING,
      });

      dispatch(toggleConfirmationDialog());
    })
      .then(() => {
        dispatch({
          ...action,
          confirmationDialog: null, // do not ask for confirmation again
        });

        invokeIfFunction(onConfirm);
      })
      .catch(() => invokeIfFunction(onCancel))
      .finally(() => {
        dispatch(toggleConfirmationDialog());
      });
  };

export default confirmationMiddleware;
