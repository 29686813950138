import { useState, useCallback } from 'react';

/**
 * Simple hook used for toggling state on and off
 *
 * @param {boolean} initial Initial toggle state
 */
const useToggle = (initial = false) => {
  const [isToggled, setToggled] = useState(initial);

  const toggle = useCallback(
    () => setToggled((currentIsToggled) => !currentIsToggled),
    [],
  );

  return [isToggled, toggle, setToggled];
};

export default useToggle;
