import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import AltusLogo from '../Logo';
import { invokeIfFunction } from '../utils';

const LogoAndAppTitle = ({
  to,
  Logo,
  title,
  spacing,
  classes,
  renderLogo,
  environmentName,
}) => {
  const logo = renderLogo ? invokeIfFunction(renderLogo) : <Logo />;

  return (
    <Grid
      item
      container
      spacing={spacing}
      component={to ? Link : undefined}
      to={to ? to : undefined}
      alignItems="center"
    >
      <Grid item>{logo}</Grid>
      <Grid item>
        <Typography
          color="secondary"
          variant="subtitle1"
          className={classes.title}
        >
          {title}
        </Typography>
      </Grid>
      {!!environmentName && (
        <Grid item>
          <Typography variant="subtitle1" className={classes.environmentName}>
            <i>{environmentName}</i>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const styles = (theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  environmentName: {
    color: theme.palette.secondary.main,
  },
});

LogoAndAppTitle.defaultProps = {
  Logo: AltusLogo,
  spacing: 2,
};

LogoAndAppTitle.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  Logo: PropTypes.elementType,
};

export default compose(withStyles(styles, { name: 'LogoAndAppTitle' }))(
  LogoAndAppTitle,
);
