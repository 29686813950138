import { IgcDockManagerResourceStrings } from '../components/dockmanager/dockmanager.public-interfaces';

/**
 * Spanish resource strings for the Dock Manager
 */
export const IgcDockManagerResourceStringsES: IgcDockManagerResourceStrings = {
  close: 'Cerrar',
  pin: 'Anclar',
  unpin: 'Desanclar',
  maximize: 'Maximizar',
  minimize: 'Minimizar',
  moreOptions: 'Más opciones',
  moreTabs: 'Más fichas',
  panes: 'Panes',
  documents: 'Documents'
};
