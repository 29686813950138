import { Map } from 'immutable';

import { ACTIONS } from './notification.constants';

const initialState = Map();

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ADD_NOTIFICATION: {
      const { message } = action.notification;

      if (!message) return state;

      return Map(action.notification);
    }

    case ACTIONS.REMOVE_NOTIFICATION:
      return initialState;

    default:
      return state;
  }
};

export default notificationReducer;
