import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import { generatePath } from 'react-router';
import React, { PureComponent } from 'react';

import BasePage from '../BasePage';
import NavigationTile from '../Tile/NavigationTile';

class OverviewContainer extends PureComponent {
  render() {
    const {
      Icon,
      match,
      dataState,
      parentRoutes,
      parentBreadcrumb,
      BasePageProps,
      NavigationTileProps,
    } = this.props;

    return (
      <BasePage
        Icon={Icon}
        dataState={dataState}
        title={parentBreadcrumb}
        {...BasePageProps}
      >
        <Grid container spacing={2}>
          {parentRoutes
            .filter((route) => !route.hidden)
            .map((route) => {
              const { path, target } = route;

              const url = match ? generatePath(path, match.params) : path;

              return (
                <Grid item md={4} lg={2} xs={12} key={url}>
                  <NavigationTile
                    to={url}
                    target={target}
                    {...route}
                    {...NavigationTileProps}
                  />
                </Grid>
              );
            })}
        </Grid>
      </BasePage>
    );
  }
}

OverviewContainer.defaultProps = {
  parentBreadcrumb: 'Overview',
};

export default compose()(OverviewContainer);
