import moment from 'moment';
import max from 'lodash/max';
import { compose } from 'redux';
import range from 'lodash/range';
import throttle from 'lodash/throttle';
import memoize from 'lodash/memoize';
import { Grid } from '@material-ui/core';
import React, { PureComponent } from 'react';

import { EMPTY_LIST } from '../constants';
import { invokeIfFunction } from '../utils';
import CalendarMonth from './CalendarMonth';
import CalendarDaysHeader from './CalendarDaysHeader';
import CalendarNavigation from './CalendarNavigation';
import CalendarMonthsSidebar from './CalendarMonthsSidebar';

class CalendarYear extends PureComponent {
  constructor(props) {
    super(props);

    const { year } = props;

    this.getMonthsMemoized = memoize(this.getMonths);

    this.state = {
      year,
      selectedDate: null,
      allMonths: this.getMonthsMemoized(year),
      numberOfDays: this.getMaxNumberOfDays(year, this.getMonthsMemoized(year)),
      startDate: moment({ year }).startOf('year').startOf('isoWeek'),
    };

    this.setSelectedRangeThrottled = throttle(this.setSelectedRange, 300, {
      leading: true,
      trailing: true,
    });
  }

  getAllDaysInSelectedYear = (year) => {
    const { numberOfMonths } = this.props;

    const months = range(0, numberOfMonths);
    this.maxNumberOfDays = this.getMaxNumberOfDays(year, months);

    return months;
  };

  getMonths = () => {
    const { numberOfMonths } = this.props;

    return range(0, numberOfMonths);
  };

  getMaxNumberOfDays = (year, months) => {
    return max(
      months.map((month) => {
        const lastDayOfMonth = moment({ year, month }).endOf('month');
        const firstDayOfMonth = moment({ year, month })
          .startOf('month')
          .startOf('isoWeek');

        return lastDayOfMonth.diff(firstDayOfMonth, 'days') + 1;
      }),
    );
  };

  getStartAndEndDate = (year) => {
    const { numberOfMonths } = this.props;

    return [
      moment({ year }).startOf('year').startOf('isoWeek'),
      moment({ year }).add(numberOfMonths, 'months').endOf('month'),
    ];
  };

  changeYear = (year) => {
    const { onYearChange } = this.props;

    const [startDate, endDate] = this.getStartAndEndDate(year);

    this.setState(
      {
        year,
        allMonths: this.getMonths(year),
        numberOfDays: this.getMaxNumberOfDays(
          year,
          this.getMonthsMemoized(year),
        ),
      },
      () => invokeIfFunction(onYearChange, startDate, endDate),
    );
  };

  setSelectedRange = (startDate, endDate, callback) => {
    const { selectedEndDate } = this.state;

    if (endDate === selectedEndDate) {
      return;
    }

    this.setState(
      {
        selectedEndDate: endDate,
        selectedStartDate: startDate,
      },
      callback,
    );
  };

  render() {
    const { year } = this.state;

    const {
      dateRanges,
      DayComponent,
      onSelectDates,
      numberOfMonths,
      DateRangeComponent,
      DateRangeEndComponent,
      DateRangeStartComponent,
      DateRangeComponentProps,
    } = this.props;

    return (
      <Grid>
        <CalendarNavigation year={year} changeYear={this.changeYear} />
        <Grid container wrap="nowrap">
          <Grid item>
            <CalendarMonthsSidebar
              year={year}
              onSelectDates={onSelectDates}
              numberOfMonths={numberOfMonths}
            />
          </Grid>
          <Grid container item xs>
            <CalendarDaysHeader
              startDate={this.state.startDate}
              numberOfDays={this.state.numberOfDays}
            />
            {this.state.allMonths.map((month) => {
              return (
                <CalendarMonth
                  year={year}
                  key={month}
                  month={month}
                  dateRanges={dateRanges}
                  DayComponent={DayComponent}
                  onSelectDates={onSelectDates}
                  DateRangeComponent={DateRangeComponent}
                  maxNumberOfDays={this.state.numberOfDays}
                  selectedEndDate={this.state.selectedEndDate}
                  DateRangeEndComponent={DateRangeEndComponent}
                  selectedStartDate={this.state.selectedStartDate}
                  setSelectedRange={this.setSelectedRangeThrottled}
                  DateRangeStartComponent={DateRangeStartComponent}
                  DateRangeComponentProps={DateRangeComponentProps}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

CalendarYear.defaultProps = {
  numberOfMonths: 12,
  year: moment().year(),
  dateRanges: EMPTY_LIST,
};

export default compose()(CalendarYear);
