import { compose } from 'redux';
import React, { PureComponent } from 'react';
import { Route, NavLink } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import { Typography, MenuItem } from '@material-ui/core';

class MenuNavigationItem extends PureComponent {
  render() {
    const { title, to, classes } = this.props;

    return (
      <Route
        path={to}
        title={title}
        children={({ match }) => (
          <MenuItem
            to={to}
            title={title}
            selected={!!match}
            component={NavLink}
            classes={{
              root: classes.menuItem,
            }}
          >
            <Typography variant="subtitle1" noWrap>
              {title}
            </Typography>
          </MenuItem>
        )}
      />
    );
  }
}

const styles = () => ({
  menuItem: {},
});

export default compose(
  withStyles(styles, {
    name: 'MenuNavigationItem',
  }),
)(MenuNavigationItem);
