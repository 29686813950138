import { compose } from 'redux';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import React, { Children } from 'react';
import { Grid } from '@material-ui/core';

import { TableRowAction } from '../Table';

const TableRowActionsCell = ({ children, minItems, ...rest }) => {
  const placeholders = range(0, minItems - Children.count(children), 1);

  return (
    <Grid container wrap="nowrap">
      {placeholders.map((index) => (
        <TableRowAction key={index} {...rest} />
      ))}
      {Children.map(children, (action, index) => (
        <TableRowAction key={index} {...rest}>
          {action}
        </TableRowAction>
      ))}
    </Grid>
  );
};

TableRowActionsCell.defaultProps = {
  minItems: 0,
};

TableRowActionsCell.propTypes = {
  children: PropTypes.node,
  minItems: PropTypes.number,
};

export default compose(React.memo)(TableRowActionsCell);
