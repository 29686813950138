import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { Grid, Icon } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import withStyles from '@material-ui/styles/withStyles';

import { invokeIfFunction } from '../utils';

const TableHeaderCell = ({
  xs,
  title,
  render,
  Header,
  classes,
  canSort,
  isSorted,
  isSortedDesc,
  getHeaderProps,
  getSortByToggleProps,
}) => {
  const sortByToggleProps = invokeIfFunction(getSortByToggleProps);

  const resolvedTitle = isString(title)
    ? title
    : isString(Header)
    ? Header
    : undefined;

  return (
    <Grid
      {...getHeaderProps(sortByToggleProps)}
      item
      xs={xs}
      title={resolvedTitle}
      className={classNames(classes.root, {
        [classes.xs]: isNumber(xs),
        [classes.sortedColumn]: isSorted && canSort,
        [classes.sortableColumn]: !isSorted && canSort,
      })}
    >
      <Grid className={classes.columnTitle}>{render('Header')}</Grid>
      {canSort && (
        <Icon
          fontSize="small"
          className={classes.columnIcon}
          component={isSortedDesc ? ExpandMore : ExpandLess}
        />
      )}
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: `0 ${theme.spacing(2)}px`,
    height: theme.altus.components.SortableListHeader.columnHeight,
  },
  columnTitle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    ...theme.typography.body2,
    fontWeight: 700,
  },
  sortedColumn: {
    'background': theme.altus.components.SortableListHeader.columnBackground,
    '& $columnIcon': {
      visibility: 'visible',
    },
  },
  sortableColumn: {
    '&:hover $columnIcon': {
      visibility: 'visible',
    },
  },
  columnIcon: {
    visibility: 'hidden',
  },
  xs: {
    overflow: 'hidden',
  },
});

export default compose(
  React.memo,
  withStyles(styles, { name: 'TableHeaderCell' }),
)(TableHeaderCell);
