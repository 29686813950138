import { compose } from 'redux';
import isString from 'lodash/isString';
import { NavLink } from 'react-router-dom';
import { generatePath } from 'react-router';
import withStyles from '@material-ui/styles/withStyles';
import React, { PureComponent, createElement } from 'react';
import { Grid, Typography, ButtonBase } from '@material-ui/core';

class ApplicationSidebarMenuItem extends PureComponent {
  isMenuItemActive = (match) => {
    const { depth, maxDepth } = this.props;

    return match ? (match.isExact ? true : depth === maxDepth - 1) : false;
  };

  render() {
    const { match, route, classes } = this.props;
    const { breadcrumb, Icon, path } = route;

    const to = match ? generatePath(path, match.params) : path;

    return (
      <NavLink
        to={to}
        isActive={this.isMenuItemActive}
        activeClassName={classes.activeMenuItem}
        title={isString(breadcrumb) ? breadcrumb : undefined}
        onClick={() =>
          to === window.location.pathname ? window.location.reload() : undefined
        }
      >
        <Grid
          container
          wrap="nowrap"
          component={ButtonBase}
          className={classes.menuItemButton}
        >
          <Grid
            item
            color="primary"
            component={Icon}
            className={classes.menuItemIcon}
          />
          <Grid
            xs
            item
            noWrap
            align="left"
            variant="body2"
            color="primary"
            component={Typography}
            className={classes.menuItemText}
          >
            {isString(breadcrumb) && breadcrumb}
            {!isString(breadcrumb) && createElement(breadcrumb, { match })}
          </Grid>
        </Grid>
      </NavLink>
    );
  }
}

const styles = (theme) => ({
  activeMenuItem: {
    '& $menuItemButton': {
      background:
        theme.altus.components.ApplicationSidebarMenuItem.background.active,
    },
    '& $menuItemText': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  menuItemButton: {
    'padding': `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    'paddingLeft': ({ depth }) => depth * theme.spacing(4) + theme.spacing(1),
    '&:hover': {
      background:
        theme.altus.components.ApplicationSidebarMenuItem.background.hover,
    },
  },
  menuItemIcon: {
    marginRight: theme.spacing(1),
    color: theme.altus.components.ApplicationSidebarMenuItem.iconColor,
  },
  menuItemText: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.altus.components.ApplicationSidebarMenuItem.textColor,
  },
});

ApplicationSidebarMenuItem.defaultProps = {
  depth: 1,
  maxDepth: 2,
};

export default compose(
  withStyles(styles, {
    name: 'ApplicationSidebarMenuItem',
  }),
)(ApplicationSidebarMenuItem);
