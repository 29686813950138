import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import { List } from 'immutable';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import withStyles from '@material-ui/styles/withStyles';

import CalendarDay from './CalendarDay';
import { EMPTY_LIST } from '../constants';
import CalendarPlaceholderDay from './CalendarPlaceholderDay';

const dateIsWithinDateRange = (date, startDate, endDate) =>
  date.isBetween(startDate, endDate, 'day', '[]');

const isSelected = (date, selectedStartDate, selectedEndDate) =>
  selectedStartDate && selectedEndDate
    ? dateIsWithinDateRange(date, selectedStartDate, selectedEndDate)
    : false;

const datesIncludeDate = (dateToLookup, dates = EMPTY_LIST) =>
  dates.some((date) => date.isSame(dateToLookup, 'day'));

const CalendarRange = ({
  end,
  month,
  start,
  dayWidth,
  DayComponent,
  onSelectDates,
  availableDates,
  selectedEndDate,
  setSelectedRange,
  selectedStartDate,
  DateRangeComponent,
  dateRange: dateRanges,
  DateRangeEndComponent,
  DateRangeStartComponent,
  DateRangeComponentProps,
}) => {
  const actualRangeDates = List(moment.range(start, end).by('day'));

  const dateRangeStartDates = dateRanges.map((dateRange) =>
    dateRange.get('startDate'),
  );
  const dateRangeEndDates = dateRanges.map((dateRange) =>
    dateRange.get('endDate'),
  );

  const dates = availableDates
    .filter((ad) => datesIncludeDate(ad, actualRangeDates))
    .groupBy((date) => date.month() === month);

  const datesMatchingMonth = dates.get(true, EMPTY_LIST);
  const datesNotMatchingMonth = dates.get(false, EMPTY_LIST);

  const datesWithinDateRange = datesMatchingMonth.filter((date) => {
    if (datesIncludeDate(date, dateRangeStartDates))
      return !DateRangeStartComponent;
    if (datesIncludeDate(date, dateRangeEndDates))
      return !DateRangeEndComponent;

    return true;
  });

  const classes = useStyles({ dayWidth, datesWithinDateRange });

  return (
    <>
      {datesNotMatchingMonth
        .filter((date) => date.isBefore(datesMatchingMonth.first()))
        .map((date, index) => (
          <CalendarPlaceholderDay key={index} date={date} width={dayWidth} />
        ))}
      {DateRangeStartComponent &&
        datesMatchingMonth.some((date) =>
          datesIncludeDate(date, dateRangeStartDates),
        ) && (
          <CalendarDay
            date={start}
            month={month}
            width={dayWidth}
            dateRanges={dateRanges}
            onSelectDates={onSelectDates}
            setSelectedRange={setSelectedRange}
            DateRangeStartComponent={DateRangeStartComponent}
            isSelected={isSelected(start, selectedStartDate, selectedEndDate)}
          />
        )}
      <Grid className={classes.root}>
        {datesWithinDateRange.map((date, index) => (
          <CalendarDay
            date={date}
            key={index}
            month={month}
            DayComponent={DayComponent}
            onSelectDates={onSelectDates}
            setSelectedRange={setSelectedRange}
            isSelected={isSelected(date, selectedStartDate, selectedEndDate)}
          />
        ))}
        {DateRangeComponent && (
          <DateRangeComponent
            endDate={end}
            startDate={start}
            dateRanges={dateRanges}
            {...DateRangeComponentProps}
          />
        )}
      </Grid>
      {DateRangeEndComponent &&
        datesMatchingMonth.some((date) =>
          datesIncludeDate(date, dateRangeEndDates),
        ) && (
          <CalendarDay
            date={end}
            month={month}
            width={dayWidth}
            dateRanges={dateRanges}
            onSelectDates={onSelectDates}
            setSelectedRange={setSelectedRange}
            DateRangeEndComponent={DateRangeEndComponent}
            isSelected={isSelected(end, selectedStartDate, selectedEndDate)}
          />
        )}
      {datesNotMatchingMonth
        .filter((date) => date.isAfter(datesMatchingMonth.last()))
        .map((date, index) => (
          <CalendarPlaceholderDay key={index} date={date} width={dayWidth} />
        ))}
    </>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    width: ({ dayWidth, datesWithinDateRange }) =>
      `calc(${dayWidth} * ${datesWithinDateRange.size})`,
  },
});

const styles = () => ({
  calendarRangeRoot: {
    display: 'flex',
    position: 'relative',
  },
});

export default compose(
  React.memo,
  withStyles(styles, { name: 'CalendarRange' }),
)(CalendarRange);
