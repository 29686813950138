import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { connect } from 'react-redux';
import isNumber from 'lodash/isNumber';
import Close from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import withStyles from '@material-ui/styles/withStyles';
import CheckCircle from '@material-ui/icons/CheckCircle';

import {
  Snackbar,
  IconButton,
  SnackbarContent,
  CircularProgress,
} from '@material-ui/core';

import {
  removeNotification,
  getNotificationSelector,
  NOTIFICATION_VARIANTS,
} from 'altus-redux-middlewares';

const variants = {
  [NOTIFICATION_VARIANTS.SUCCESS]: {
    Icon: CheckCircle,
    duration: 5000,
  },
  [NOTIFICATION_VARIANTS.ERROR]: {
    Icon: ErrorIcon,
    duration: 2000,
  },
  [NOTIFICATION_VARIANTS.INFO]: {
    Icon: ({ className }) => (
      <CircularProgress size={20} className={className} />
    ),
  },
};
const noAutoHideDuration = null;

const NotificationContainer = ({
  dispatchRemoveNotification,
  classes,
  notification,
}) => {
  const variant = notification.get('variant');
  const message = notification.get('message');
  const hideable = notification.get('hideable', true);

  if (!variant) {
    return null;
  }

  const { Icon, duration: defaultDuration } = variants[variant];

  let configuredDuration = notification.get('duration');
  if (
    !isNumber(configuredDuration) &&
    configuredDuration !== noAutoHideDuration
  ) {
    // If duration is not configured for this variant, use the defaultDuration.
    configuredDuration = defaultDuration;
  }

  const showCloseButton = hideable || configuredDuration === noAutoHideDuration;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={!!notification}
      disableWindowBlurListener
      onClose={dispatchRemoveNotification}
      autoHideDuration={configuredDuration}
      id="id_notificationContainer"
    >
      {notification && message && (
        <SnackbarContent
          className={classNames(classes[variant])}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
              {message}
            </span>
          }
          action={[
            showCloseButton && (
              <IconButton
                key="close"
                color="inherit"
                aria-label="Close"
                className={classes.close}
                onClick={dispatchRemoveNotification}
              >
                <Close className={classes.icon} />
              </IconButton>
            ),
          ]}
        />
      )}
    </Snackbar>
  );
};

const styles = (theme) => ({
  [NOTIFICATION_VARIANTS.SUCCESS]: {
    color: theme.palette.tertiary.contrastText,
    backgroundColor: theme.palette.tertiary.main,
  },
  [NOTIFICATION_VARIANTS.ERROR]: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
  [NOTIFICATION_VARIANTS.INFO]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default compose(
  connect(
    (state) => ({
      notification: getNotificationSelector(state),
    }),
    {
      dispatchRemoveNotification: removeNotification,
    },
  ),
  withStyles(styles),
)(NotificationContainer);
