import { compose } from 'redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Box, Grid, Divider, Typography } from '@material-ui/core';

import {
  useTable,
  useSortBy as useSortByPlugin,
  useExpanded as useExpandedPlugin,
  useRowSelect as useRowSelectPlugin,
  usePagination as usePaginationPlugin,
  useGlobalFilter as useGlobalFilterPlugin,
} from 'react-table';

import {
  TableRow,
  TableCell,
  TableBody,
  TableFilter,
  TableHeader,
  TableRowAction,
  TableHeaderRow,
  TableHeaderCell,
  TablePaginationFooter,
} from '../Table';

import { invokeIfFunction } from '../utils';

const Table = ({
  theme,
  items,
  classes,
  columns,
  onRowClick,
  renderIcon, // TODO: Integrate renderIcon into columns
  useExpanded,
  stickyHeader,
  useRowSelect,
  showGoToPage,
  usePagination,
  onRowSelected,
  displayFooters,
  onPageChange,
  useGlobalFilter = true,
  noItemsMessage = 'No items..',
  TableBodyProps,
  subrowOffset = 5,
  displayHeaders = true,
  displayNoItemsMessage = true,
  TableRowComponent = TableRow,
  TableBodyComponent = TableBody,
  TableCellComponent = TableCell,
  TableHeaderComponent = TableHeader,
  TableHeaderRowComponent = TableHeaderRow,
  TableHeaderCellComponent = TableHeaderCell,
  ...rest
}) => {
  const data = React.useMemo(() => items.valueSeq(), [items]);

  const plugins = [
    // plugin order need to stay like this
    useGlobalFilterPlugin,
    useSortByPlugin,
    useExpanded ? useExpandedPlugin : undefined,
    usePagination ? usePaginationPlugin : undefined,
    useRowSelectPlugin,
  ].filter(Boolean);

  const {
    page,
    rows,
    state,
    prepareRow,
    headerGroups,
    footerGroups,
    globalFilter,
    getTableProps,
    setGlobalFilter,
    getTableBodyProps,
    preGlobalFilteredRows,
    getToggleAllRowsSelectedProps,

    // Pagination
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      data,
      columns,
      ...rest,
    },
    ...plugins,
  );

  const { selectedRowIds, pageIndex, pageSize } = state;

  const isAnyRowSelected = useMemo(
    () => !!Object.keys(selectedRowIds).length,
    [selectedRowIds],
  );

  const allRows = usePagination ? page : rows;

  useEffect(() => {
    invokeIfFunction(onRowSelected, selectedRowIds);
  }, [onRowSelected, selectedRowIds]);

  useEffect(() => {
    invokeIfFunction(onPageChange, { pageIndex, pageSize });
  }, [onPageChange, pageIndex, pageSize]);

  return (
    <Grid container direction="column" {...getTableProps()}>
      {displayHeaders && (
        <TableHeaderComponent
          sticky={stickyHeader}
          renderIcon={renderIcon}
          useRowSelect={useRowSelect}
          headerGroups={headerGroups}
          TableHeaderRowComponent={TableHeaderRowComponent}
          TableHeaderCellComponent={TableHeaderCellComponent}
          getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps}
        />
      )}
      {useGlobalFilter && !!preGlobalFilteredRows.length && (
        <TableFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          rowCount={preGlobalFilteredRows.length}
        />
      )}
      <TableBodyComponent
        getTableBodyProps={getTableBodyProps}
        {...TableBodyProps}
      >
        {allRows.map((row) => {
          prepareRow(row);

          const {
            id: rowId,
            depth,
            cells,
            index,
            original,
            isSelected,
            getRowProps,
            getToggleRowSelectedProps,
          } = row;

          /**
           * subrows have a nested id like "2.4", where the first number is the index of the parent row
           * and the second is the index of the  subrow
           */
          const collection = depth ? parseInt(rowId, 10) : null;

          const offsetPx = theme.spacing(subrowOffset) * depth;

          return (
            <TableRowComponent
              row={row}
              index={index}
              depth={depth}
              item={original}
              offsetPx={offsetPx}
              isSelected={isSelected}
              onRowClick={onRowClick}
              renderIcon={renderIcon}
              collection={collection}
              getRowProps={getRowProps}
              useRowSelect={useRowSelect}
              isAnyRowSelected={isAnyRowSelected}
              getToggleRowSelectedProps={getToggleRowSelectedProps}
              {...getRowProps()}
            >
              {cells.map((cell, cellIndex) => {
                const { value, column, getCellProps, render } = cell;
                const { xs } = column;

                return (
                  <TableCellComponent
                    item
                    xs={xs}
                    title={value}
                    column={column}
                    rowDepth={depth}
                    index={cellIndex}
                    subrowOffset={subrowOffset}
                    {...getCellProps()}
                  >
                    {render('Cell')}
                  </TableCellComponent>
                );
              })}
            </TableRowComponent>
          );
        })}
        {displayNoItemsMessage && !rows.length && (
          <Box component={Grid} padding={2} container justifyContent="center">
            <TableCellComponent>
              <Typography variant="body2" className={classes.noItemsMessage}>
                {noItemsMessage}
              </Typography>
            </TableCellComponent>
          </Box>
        )}
      </TableBodyComponent>
      {displayFooters &&
        !!rows.length &&
        footerGroups.map((footerGroup) => {
          const { headers, getFooterGroupProps } = footerGroup;

          return (
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              className={classes.footer}
              {...getFooterGroupProps()}
            >
              {renderIcon && <TableRowAction transparent />}
              {headers.map((header) => {
                const { xs, render, getFooterProps } = header;

                return (
                  <TableCellComponent
                    item
                    xs={xs}
                    component={Grid}
                    {...getFooterProps()}
                  >
                    {render('Footer')}
                  </TableCellComponent>
                );
              })}
            </Grid>
          );
        })}
      {usePagination && !!rows.length && (
        <>
          <Divider />
          <TablePaginationFooter
            gotoPage={gotoPage}
            nextPage={nextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            canNextPage={canNextPage}
            showGoToPage={showGoToPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
          />
        </>
      )}
    </Grid>
  );
};

const styles = (theme) => ({
  footer: {
    padding: `${theme.spacing(2)}px 0 `,
  },
  noItemsMessage: {
    color: theme.palette.text.hint,
  },
});

Table.propTypes = {
  renderIcon: PropTypes.func,
  onRowClick: PropTypes.func,
  getSubRows: PropTypes.func,
  useExpanded: PropTypes.bool,
  onPageChange: PropTypes.func,
  useRowSelect: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  usePagination: PropTypes.bool,
  onRowSelected: PropTypes.func,
  displayFooters: PropTypes.bool,
  displayHeaders: PropTypes.bool,
  subrowOffset: PropTypes.number,
  useGlobalFilter: PropTypes.bool,
  noItemsMessage: PropTypes.string,
  TableBodyProps: PropTypes.object,
  displayNoItemsMessage: PropTypes.bool,
  TableRowComponent: PropTypes.elementType,
  TableBodyComponent: PropTypes.elementType,
  TableCellComponent: PropTypes.elementType,
  TableHeaderComponent: PropTypes.elementType,
  TableHeaderRowComponent: PropTypes.elementType,
  TableHeaderCellComponent: PropTypes.elementType,
  items: PropTypes.instanceOf(Immutable.Collection),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.node,
      ]),
    }),
  ).isRequired, // see https://react-table.tanstack.com/docs/api/useTable#column-options for other column options
};

export default compose(
  React.memo, // comment this line for storybook
  withStyles(styles, { name: 'Table', withTheme: true }),
)(Table);
