import { compose } from 'redux';
import React, { PureComponent } from 'react';

import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

class SortableListRowActionColumn extends PureComponent {
  render() {
    const { classes, children, ...rest } = this.props;

    return (
      <Grid item className={classes.actionColumn} {...rest}>
        {children}
      </Grid>
    );
  }
}

const styles = (theme) => ({
  actionColumn: {
    minWidth: theme.altus.components.SortableListRow.height,
    maxWidth: theme.altus.components.SortableListRow.height,
  },
});

export default compose(
  withStyles(styles, { name: 'SortableListRowActionColumn' }),
)(SortableListRowActionColumn);
