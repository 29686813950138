import { compose } from 'redux';
import React, { PureComponent } from 'react';
import { Grid, Drawer } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import HeaderBase from '../HeaderBase';
import { invokeIfFunction } from '../utils';
import ApplicationSidebarMenu from './ApplicationSidebarMenu';

class ApplicationSidebar extends PureComponent {
  render() {
    const {
      classes,
      routes,
      renderHeader,
      renderTopContent,
      renderBottomContent,
      ApplicationSidebarMenuProps,
    } = this.props;

    return (
      <Drawer
        variant="permanent"
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
      >
        <HeaderBase
          disableGutters={true}
          classes={{
            root: classes.header,
          }}
        >
          {invokeIfFunction(renderHeader)}
        </HeaderBase>

        {invokeIfFunction(renderTopContent)}

        <Grid xs item className={classes.contentContainer}>
          <ApplicationSidebarMenu
            routes={routes}
            {...ApplicationSidebarMenuProps}
          />
        </Grid>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.topBottomContainer}
        >
          {invokeIfFunction(renderBottomContent)}
        </Grid>
      </Drawer>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: theme.altus.components.ApplicationSidebar.width,
  },
  paper: {
    overflow: 'hidden',
    borderRight: 'none',
    position: 'relative',
    background: theme.altus.components.ApplicationSidebar.background,
  },
  contentContainer: {
    overflowY: 'auto',
  },
  topBottomContainer: {
    padding: theme.spacing(2),
  },
  header: {
    background: theme.altus.components.ApplicationSidebar.header.background,
    padding: theme.altus.components.ApplicationSidebar.header.padding,
  },
});

export default compose(
  withStyles(styles, {
    name: 'ApplicationSidebar',
  }),
)(ApplicationSidebar);
