import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import React, { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Paper, Typography } from '@material-ui/core';

import Logo from '../Logo';
import HeaderBase from '../HeaderBase';
import { isLoading } from '../utils';
import background from '../assets/login.jpg';
import LoadingButton from '../LoadingButton';

class LoginPage extends PureComponent {
  render() {
    const { title, classes, onLogin, rootUrl, dataState, currentUser } =
      this.props;

    return (
      <React.Fragment>
        {currentUser.get('id') && <Redirect to={rootUrl} />}
        <HeaderBase>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Logo />
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle2"
                color="white"
                className={classes.title}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
        </HeaderBase>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Grid container alignItems="center" spacing={4} direction="column">
              <Grid item>
                <Typography variant="h6">Authentication Required</Typography>
              </Grid>
              <Grid item>
                <Typography>You need to login to continue.</Typography>
              </Grid>
              <Grid item>
                <LoadingButton
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={onLogin}
                  loading={isLoading(dataState)}
                >
                  Login
                </LoadingButton>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({
  root: {
    'width': '100vw',
    'display': 'flex',
    'position': 'fixed',
    'alignItems': 'center',
    'justifyContent': 'center',
    'height': '100vh',
    '&:before': {
      zIndex: -1,
      opacity: 0.5,
      width: '100%',
      height: '100%',
      display: 'block',
      position: 'fixed',
      backgroundSize: 'cover',
      filter: 'saturate(50%)',
      content: 'no-open-quote',
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url("${background}")`,
    },
  },
  paper: {
    padding: 40,
    width: '25vw',
  },
  appBarColorPrimary: {
    backgroundColor: theme.altus.background.header,
  },
  title: {
    fontWeight: 700,
  },
});

export default compose(withStyles(styles, { name: 'LoginPage' }))(LoginPage);
