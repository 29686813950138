import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { PureComponent } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { Grid, Paper } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { blue, green, red } from '@material-ui/core/colors';

export const AlertType = {
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
};

export class Alert extends PureComponent {
  render() {
    const { classes, Icon, children, type } = this.props;

    return (
      <Grid
        square
        container
        component={Paper}
        alignItems="center"
        classes={{
          root: classNames(classes.root, {
            [classes[type]]: !!type,
          }),
        }}
      >
        {children}
        <Grid container>
          {Icon && (
            <Grid item>
              <Icon className={classes.icon} />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

Alert.defaultProps = {
  Icon: InfoIcon,
};

Alert.propTypes = {
  children: PropTypes.node,
  Icon: PropTypes.elementType,
  type: PropTypes.oneOf(Object.values(AlertType)),
};

const styles = (theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    color: 'initial',
  },
  [AlertType.INFO]: {
    'background': blue[50],
    '& $icon': {
      fill: blue[500],
    },
  },
  [AlertType.ERROR]: {
    'background': red[50],
    '& $icon': {
      fill: red[500],
    },
  },
  [AlertType.SUCCESS]: {
    'background': green[50],
    '& $icon': {
      fill: green[500],
    },
  },
  icon: {},
});

export default compose(withStyles(styles))(Alert);
