import React from 'react';
import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { DialogActions } from '@material-ui/core';

const ModalActions = ({ classes, children }) => (
  <DialogActions
    classes={{
      root: classes.dialogActionsRoot,
    }}
  >
    {children}
  </DialogActions>
);

const styles = () => ({
  dialogActionsRoot: {
    justifyContent: 'flex-end',
  },
});

export default compose(React.memo, withStyles(styles))(ModalActions);
