import { Map } from 'immutable';

import {
  ACTIONS,
  LoadingDataState,
  FailedDataState,
  ReceivedDataState,
} from './dataState.constants';

const initialState = Map();

const dataStateReducer = (state = initialState, action) => {
  const { subtype, id, type, silent, error } = action;

  if (silent) {
    return state;
  }

  switch (type) {
    case ACTIONS.ASYNC_START: {
      const dataState = LoadingDataState;

      return id
        ? state.setIn([subtype, id.toString()], dataState)
        : state.set(subtype, dataState);
    }
    case ACTIONS.ASYNC_END: {
      const dataState = error ? FailedDataState : ReceivedDataState;

      return id
        ? state.setIn([subtype, id.toString()], dataState)
        : state.set(subtype, dataState);
    }
    default:
      return state;
  }
};

export default dataStateReducer;
