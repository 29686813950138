import { compose } from 'redux';
import debounce from 'lodash/debounce';
import React, { PureComponent } from 'react';
import Close from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import withStyles from '@material-ui/styles/withStyles';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';

import { invokeIfFunction } from '../utils';

class SearchField extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  componentDidMount() {
    const { onChange, timeout } = this.props;
    this.onChangeDebounced = debounce(onChange, timeout);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  onChange = (event) => {
    const { onKeyDown } = this.props;

    invokeIfFunction(onKeyDown, event.target.value);
    this.setState({ value: event.target.value });
    this.onChangeDebounced(event.target.value);
  };

  onClear = () => {
    const { inputRef, onClear } = this.props;

    this.setState({ value: '' });
    this.onChangeDebounced('');

    invokeIfFunction(onClear);

    if (inputRef.current) inputRef.current.focus();
  };

  render() {
    const { classes, inputRef, TextFieldProps, Icon } = this.props;

    const displayClearBtn = this.state.value && inputRef;

    return (
      <TextField
        variant="outlined"
        inputRef={inputRef}
        placeholder="Search..."
        value={this.state.value}
        onChange={this.onChange}
        InputProps={{
          classes: {
            input: classes.input,
            notchedOutline: classes.outline,
          },
          endAdornment: (
            <InputAdornment position="end">
              {Icon}
              {!Icon && (
                <>
                  {displayClearBtn && (
                    <IconButton size="small" onClick={this.onClear}>
                      <Close />
                    </IconButton>
                  )}
                  {!displayClearBtn && (
                    <IconButton size="small" disabled>
                      <Search className={classes.filterIcon} />
                    </IconButton>
                  )}
                </>
              )}
            </InputAdornment>
          ),
        }}
        {...TextFieldProps}
      />
    );
  }
}

SearchField.defaultProps = {
  value: '',
  timeout: 500,
};

const styles = (theme) => ({
  input: {},
  filterIcon: {
    fill: theme.palette.grey[700],
  },
  outline: {},
});

export default compose(withStyles(styles, { name: 'SearchField' }))(
  SearchField,
);
