import { PureComponent } from 'react';

import { invokeIfFunction } from '../utils';

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError = (error) => ({ error });

  componentDidCatch(error, info) {
    const { onError } = this.props;

    invokeIfFunction(onError, error, info.componentStack);
  }

  // This allows us to reset the error from the outside if necessary, for example on a route change
  componentDidUpdate(prevProps) {
    const { error } = this.state;
    const { error: prevError } = prevProps;
    const { error: updatedError } = this.props;

    if (updatedError === error) return;
    if (updatedError === prevError) return;

    this.setState({
      error: updatedError,
    });
  }

  render() {
    const { error } = this.state;
    const { children, renderContent } = this.props;

    if (error) {
      return invokeIfFunction(renderContent, error);
    }

    return children;
  }
}

export default ErrorBoundary;
