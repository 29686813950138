import { useMemo, useEffect, useRef } from 'react';

import debounce from 'lodash/debounce';

/**
 * Hook to simplify handling of window resize events.
 * Each small change made to the window size will fire a separate resize event,
 * therefore we debounce the callback to improve performance
 *
 * @param {function} callback The function to invoke when the window resizes
 * @param {number} timeout The number of milliseconds to delay
 */
const useWindowResize = (callback, timeout = 1000) => {
  const handleResize = useRef(callback);

  const handleResizeDebounced = useMemo(
    () => debounce(handleResize.current, timeout),
    [timeout],
  );

  useEffect(() => {
    window.addEventListener('resize', handleResizeDebounced);

    return () => {
      window.removeEventListener('resize', handleResizeDebounced);
    };
  }, [handleResizeDebounced]);
};

export default useWindowResize;
