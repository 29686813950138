import React from 'react';
import moment from 'moment';
import { compose } from 'redux';
import classNames from 'classnames';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, ButtonBase } from '@material-ui/core';

import { invokeIfFunction } from '../utils';

const CalendarMonthsSidebar = ({
  year,
  classes,
  onSelectDates,
  numberOfMonths,
  setSelectedRange,
}) => {
  return (
    <Grid container direction="column">
      <Grid
        item
        className={classNames(
          classes.calendarHeader,
          classes.yearMonthContainer,
        )}
      />
      {moment
        .months()
        .slice(0, numberOfMonths)
        .map((month, index) => {
          const startDate = moment({ year, month: index }).startOf('month');
          const endDate = moment({ year, month: index }).endOf('month');

          return (
            <Grid
              item
              key={month}
              zeroMinWidth
              component={onSelectDates ? ButtonBase : undefined}
              className={classNames(
                classes.calendarRow,
                classes.yearMonthContainer,
              )}
              onClick={() =>
                invokeIfFunction(onSelectDates, startDate, endDate, () =>
                  setSelectedRange(null, null),
                )
              }
            >
              <Typography variant="body2" noWrap>
                {month}
              </Typography>
            </Grid>
          );
        })}
    </Grid>
  );
};

const styles = (theme) => ({
  yearMonthContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: theme.palette.grey[200],
    boxShadow: `inset 0px 0px 1px 1px ${theme.palette.background.default}`,
  },
  calendarRow: {
    height: theme.altus.mediumRow.height,
  },
  calendarHeader: {
    padding: 2,
    height: theme.altus.row.height + 5,
    background: theme.palette.grey[200],
  },
});

export default compose(
  withStyles(styles, { name: 'CalendarMonthsSidebar' }),
  React.memo,
)(CalendarMonthsSidebar);
