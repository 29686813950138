import React from 'react';
import { compose } from 'redux';
import { grey } from '@material-ui/core/colors';

const MissingRequirements = () => (
  <>
    <circle cx="12" cy="12" r="12" fill={grey[600]} />
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
  </>
);

export default compose(React.memo)(MissingRequirements);
