import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const Helmet = ({ appName, titleTemplate, defer, children, ...rest }) => {
  if (!titleTemplate && !appName) {
    return (
      <ReactHelmet defer={defer} {...rest}>
        {children}
      </ReactHelmet>
    );
  }

  return (
    <ReactHelmet
      titleTemplate={titleTemplate ? titleTemplate : `%s - ${appName}`}
      defaultTitle={appName}
      defer={defer}
      {...rest}
    >
      {children}
    </ReactHelmet>
  );
};

Helmet.defaultProps = {
  defer: false,
};

Helmet.propTypes = {
  appName: PropTypes.string,
  defer: PropTypes.bool,
  titleTemplate: PropTypes.string,
  children: PropTypes.node,
};

export default Helmet;
