import { useState, useCallback } from 'react';

import {
  FailedDataState,
  LoadingDataState,
  ReceivedDataState,
  NotRequestedDataState,
} from 'altus-datastate';

const useRequestWithDataState = (request) => {
  const [dataState, setDataState] = useState(NotRequestedDataState);

  const clearDataState = useCallback(
    () => setDataState(NotRequestedDataState),
    [setDataState],
  );

  const setLoadingDataState = () => setDataState(LoadingDataState);

  const requestWithDataState = useCallback(
    (...args) =>
      Promise.resolve()
        .then(setLoadingDataState)
        .then(() => request(...args))
        .then((response) => {
          setDataState(ReceivedDataState);

          return response;
        })
        .catch((error) => {
          setDataState(FailedDataState);

          throw error;
        }),
    [request],
  );

  return [requestWithDataState, dataState, clearDataState];
};

export default useRequestWithDataState;
