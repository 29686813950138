import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import isString from 'lodash/isString';
import Refresh from '@material-ui/icons/Refresh';
import withStyles from '@material-ui/styles/withStyles';

import {
  Box,
  Grid,
  Button,
  Tooltip,
  IconButton,
  Typography,
} from '@material-ui/core';

import { EMPTY_LIST } from '../constants';
import { invokeIfFunction } from '../utils';

import SearchField from '../SearchField';
import FilterDropdown from './FilterDropdown';
import SortableListRow from './SortableListRow';

const SortableListActionsHeader = ({
  items,
  title,
  search,
  classes,
  striped,
  columns,
  onRefresh,
  setFilter,
  setSearch,
  selectItem,
  transparent,
  clearFilter,
  listActions,
  deselectItems,
  selectedItems,
  displaySearchField,
  filter: listFilter,
}) => {
  return (
    <Grid container component={Box} displayPrint="none">
      <SortableListRow
        noborder
        striped={striped}
        classes={{
          root: classNames(classes.root, {
            [classes.rootTransparent]: transparent,
          }),
        }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                {isString(title) ? (
                  <Typography variant="h6">{title}</Typography>
                ) : (
                  title
                )}
              </Grid>
              {onRefresh && (
                <Grid item>
                  <Tooltip title="Refresh">
                    <IconButton
                      classes={{
                        root: classes.iconButtonRoot,
                      }}
                      onClick={onRefresh}
                    >
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {listActions.map((listAction, index) => {
                const { renderAction, getTitle } = listAction;

                return (
                  <Grid item key={index}>
                    <Tooltip title={getTitle(selectedItems)}>
                      <Grid>
                        {invokeIfFunction(renderAction, {
                          selectedItems,
                          deselectItems,
                          selectItem,
                        })}
                      </Grid>
                    </Tooltip>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid xs item container>
            <Grid
              container
              spacing={3}
              justifyContent="flex-end"
              alignItems="center"
            >
              {columns.some(({ filter }) => !!filter) && (
                <Grid item>
                  <Tooltip title="Reset">
                    <Grid>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={clearFilter}
                        disabled={!listFilter.size}
                        className={classes.resetFilterButton}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Tooltip>
                </Grid>
              )}
              {columns.map(({ title, filter, getSortProperty }, columnNo) => {
                if (!filter) return null;

                const { defaultText = 'All', label, getFilterText } = filter;

                const currentColumnFilter = listFilter.get(columnNo.toString());

                return (
                  <Grid item key={columnNo}>
                    <FilterDropdown
                      items={items}
                      title={title}
                      label={label}
                      defaultText={defaultText}
                      value={currentColumnFilter}
                      getFilterText={getFilterText}
                      getItemValue={getSortProperty}
                      onChange={(value) => setFilter(columnNo, value)}
                    />
                  </Grid>
                );
              })}
              {displaySearchField && (
                <Grid item>
                  <SearchField
                    timeout={250}
                    value={search}
                    onChange={setSearch}
                    TextFieldProps={{
                      placeholder: 'Search items...',
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </SortableListRow>
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    height: 'auto',
    padding: `${theme.spacing(0.5)}px 0`,
    background: theme.altus.components.SortableListActionsHeader.background,
  },
  rootTransparent: {
    background: 'transparent',
  },
  iconButtonRoot: {
    padding: theme.spacing(1) + 1,
  },
  filterButton: {
    maxWidth: 175,
    textTransform: 'none',
  },
  resetFilterButton: {
    'background':
      theme.altus.components.SortableListActionsHeader.resetButton.background,
    'color': (props) =>
      props.filter.size
        ? theme.palette.getContrastText(
            theme.altus.components.SortableListActionsHeader.resetButton
              .background,
          )
        : undefined,
    '&:hover': {
      background:
        theme.altus.components.SortableListActionsHeader.resetButton.hover,
    },
  },
  filterLabel: {
    color:
      theme.altus.components.SortableListActionsHeader.filterLabel.textColor,
  },
});

SortableListActionsHeader.defaultProps = {
  columns: EMPTY_LIST,
  filter: EMPTY_LIST,
  listActions: EMPTY_LIST,
  displaySearchField: true,
};

export default compose(
  React.memo,
  withStyles(styles, {
    name: 'SortableListActionsHeader',
  }),
)(SortableListActionsHeader);
