import DataState from './DataState';

export const DATA_STATE = {
  NOT_REQUESTED: {
    requested: false,
    received: false,
    error: false,
  },
  REQUESTED: {
    requested: true,
    received: false,
    error: false,
  },
  SUCCESS: {
    requested: true,
    received: true,
    error: false,
  },
  FAILED: {
    requested: true,
    received: true,
    error: true,
  },
};

export const ACTIONS = {
  ASYNC_END: 'ASYNC_END',
  ASYNC_START: 'ASYNC_START',
};

export const LoadingDataState = new DataState(DATA_STATE.REQUESTED);
export const ReceivedDataState = new DataState(DATA_STATE.SUCCESS);
export const NotRequestedDataState = new DataState(DATA_STATE.NOT_REQUESTED);
export const FailedDataState = new DataState(DATA_STATE.FAILED);
