import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import { Tabs as MuiTabs, Divider } from '@material-ui/core';

const Tabs = ({ classes, disableUnderline, ...rest }) => (
  <>
    <MuiTabs classes={classes} {...rest} />
    {!disableUnderline && <Divider />}
  </>
);

Tabs.propTypes = {
  disableUnderline: PropTypes.bool,
};

const styles = () => ({
  indicator: {
    display: 'none',
  },
});

export default compose(withStyles(styles, { name: 'Tabs' }))(Tabs);
