import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

const TableRowAction = ({ classes, children, transparent, ...props }) => (
  <Grid item className={classes.root} {...props}>
    <Grid
      classes={{
        root: classNames({
          [classes.transparent]: transparent,
        }),
      }}
    >
      {children}
    </Grid>
  </Grid>
);

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.altus.components.SortableListRow.height,
    minWidth: theme.altus.components.SortableListRow.height,
    height: theme.altus.components.SortableListRow.height - 1,
    background: theme.altus.components.SortableListRowIcon.background,
  },
  transparent: {
    background: 'transparent',
  },
});

TableRowAction.defaultProps = {
  transparent: false,
};

export default compose(
  React.memo,
  withStyles(styles, {
    name: 'TableRowAction',
  }),
)(TableRowAction);
