import { createTheme } from '@material-ui/core/styles';
import { grey, red, blue, green, deepOrange } from '@material-ui/core/colors';

const defaultTheme = createTheme();
const DEFAULT_ACCENT_COLOR = deepOrange[700];

const defaultOptions = {
  altus: {
    button: {
      progress: 'rgba(255, 255, 255, 0.3)',
    },
    color: {
      accent: DEFAULT_ACCENT_COLOR,
    },
    background: {
      backdrop: `${grey[900]}e0`,
      header: grey[900],
      navigation: `${grey[900]}bd`,
    },
    row: {
      height: 30,
    },
    mediumRow: {
      height: 40,
    },
    components: {
      SortableListRow: {
        height: 50,
        background: 'transparent',
        stripedColor: grey[400],
        selectedColor: blue[100],
        clickableColor: `${blue[100]}42`,
        borderColor: grey[300],
        checkboxColor: defaultTheme.palette.common.white,
      },
      SortableListHeader: {
        columnHeight: 50,
        columnBackground: grey[100],
        background: defaultTheme.palette.background.default,
        border: `1px solid ${grey[300]}`,
      },
      SortableListActionsHeader: {
        background: defaultTheme.palette.background.default,
        resetButton: {
          background: DEFAULT_ACCENT_COLOR,
          hover: DEFAULT_ACCENT_COLOR,
        },
        filterLabel: {
          textColor: defaultTheme.palette.text.secondary,
        },
      },
      SortableListRowIcon: {
        background: 'transparent',
      },
      SortableListRowColumn: {
        textColor: defaultTheme.palette.text.secondary,
      },
      ApplicationSidebar: {
        width: 250,
        background: defaultTheme.palette.background.default,
        header: {
          background: 'transparent',
          padding: 1,
        },
      },
      ApplicationSidebarMenuItem: {
        background: {
          hover: defaultTheme.palette.primary.light,
          active: defaultTheme.palette.primary.light,
        },
        textColor: defaultTheme.palette.text.secondary,
        iconColor: defaultTheme.palette.text.secondary,
      },
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
    MuiTooltip: {
      popper: {
        opacity: 1,
      },
    },
    MuiFab: {
      root: {
        boxShadow: defaultTheme.shadows[2],
      },
      sizeSmall: {
        width: defaultTheme.spacing(3.75),
        height: defaultTheme.spacing(3.75),
        minHeight: defaultTheme.spacing(3.75),
      },
    },
  },
  palette: {
    error: red,
    tertiary: {
      light: green[400],
      main: green[600],
      dark: green[800],
      contrastText: defaultTheme.palette.common.white,
    },
  },
  props: {
    MuiInputLabel: {
      shrink: true,
    },
  },
  mixins: {
    tertiaryButton: {
      tertiaryButton: () => {
        return {
          'color': defaultOptions.tertiary.contrastText,
          'background': defaultOptions.tertiary.main,
          '&:hover': {
            background: defaultOptions.tertiary.dark,
          },
        };
      },
    },
  },
};

export default defaultOptions;
