import React from 'react';
import { compose } from 'redux';
import times from 'lodash/times';
import classNames from 'classnames';
import business from 'moment-business';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { formatDate } from '../utils';

const CalendarDaysHeader = ({ classes, startDate, numberOfDays }) => {
  return (
    <Grid container wrap="nowrap">
      {times(numberOfDays).map((day, index) => {
        const date = startDate.clone().add(day, 'days');

        return (
          <Grid
            item
            container
            key={index}
            zeroMinWidth
            justifyContent="center"
            alignItems="center"
            className={classNames(classes.item, classes.calendarHeader, {
              [classes.itemWeekend]: !business.isWeekDay(date),
            })}
          >
            <Typography variant="caption" noWrap>
              {formatDate(date, 'ddd')}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

const styles = (theme) => ({
  item: {
    boxShadow: `inset 0px 0px 1px 1px ${theme.palette.grey[100]}`,
  },
  itemWeekend: {
    '&$calendarHeader': {
      background: theme.palette.grey[300],
    },
  },
  calendarHeader: {
    padding: 2,
    height: theme.altus.row.height + 5,
    background: theme.palette.grey[200],
  },
});

export default compose(
  withStyles(styles, { name: 'CalendarDaysHeader' }),
  React.memo,
)(CalendarDaysHeader);
