import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isString from 'lodash/isString';
import React, { PureComponent } from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, Tooltip, Checkbox } from '@material-ui/core';

import { SORT_DIRECTION, EMPTY_LIST } from '../constants';

class SortableListHeader extends PureComponent {
  renderSortIcon = (sortDirection) => {
    const { classes, sortDirection: currentSortDirection } = this.props;

    return React.createElement(
      sortDirection === SORT_DIRECTION.ASC ? ExpandLess : ExpandMore,
      {
        fontSize: 'small',
        className: classNames(
          sortDirection === SORT_DIRECTION.ASC
            ? classes.sortArrowIconAsc
            : classes.sortArrowIconDesc,
          {
            [classes.visibleArrowIcon]: currentSortDirection === sortDirection,
          },
        ),
      },
    );
  };

  renderTitle = (title, isColumnSortable) => {
    const { classes } = this.props;

    return (
      <>
        {isString(title) ? (
          <Typography
            noWrap
            variant="subtitle2"
            className={classes.columnTitle}
          >
            {title}
          </Typography>
        ) : (
          title
        )}
        {isColumnSortable && (
          <>
            {this.renderSortIcon(SORT_DIRECTION.ASC)}

            {this.renderSortIcon(SORT_DIRECTION.DESC)}
          </>
        )}
      </>
    );
  };

  renderColumnHeader = (columnNo, getSortProperty, title, GridProps) => {
    const { classes, setSortColumn, sortedColumnNo } = this.props;

    const isCurrentSortedColumn = columnNo === sortedColumnNo;

    const isColumnSortable = !!getSortProperty;

    return (
      <Tooltip key={columnNo} title={isString(title) ? title : ''}>
        <Grid
          item
          onClick={getSortProperty ? () => setSortColumn(columnNo) : undefined}
          className={classNames(classes.column, {
            [classes.sortableColumn]: isColumnSortable,
            [classes.sortedColumn]: isCurrentSortedColumn && isColumnSortable,
            [classes.unsortedColumn]:
              !isCurrentSortedColumn && isColumnSortable,
          })}
          {...GridProps}
        >
          {this.renderTitle(title, isColumnSortable)}
        </Grid>
      </Tooltip>
    );
  };

  render() {
    const {
      noborder,
      classes,
      columns,
      actions,
      items,
      selectedItems,
      selectAll,
      selectable,
      hasIcon,
    } = this.props;

    return (
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        className={classNames(classes.root, {
          [classes.rootBorder]: !noborder,
        })}
      >
        {selectable && (
          <Grid item className={classes.iconColumn}>
            <Tooltip title={!!selectedItems.size ? 'Clear' : 'Select all'}>
              <Checkbox
                color="primary"
                onClick={selectAll}
                checked={
                  !!selectedItems.size && selectedItems.size === items.size
                }
                indeterminate={
                  !!selectedItems.size && selectedItems.size < items.size
                }
              />
            </Tooltip>
          </Grid>
        )}
        {!selectable && hasIcon && <Grid item className={classes.iconColumn} />}
        <Grid container item xs>
          {columns.map(({ title, getSortProperty, xs }, index) =>
            this.renderColumnHeader(index, getSortProperty, title, {
              xs,
            }),
          )}
        </Grid>
        {actions.map((action, index) => (
          <Grid key={index} item className={classes.iconColumn} />
        ))}
      </Grid>
    );
  }
}

const styles = (theme) => ({
  root: {
    background: theme.altus.components.SortableListHeader.background,
  },
  rootBorder: {
    border: theme.altus.components.SortableListHeader.border,
  },
  iconColumn: {
    width: 50,
    height: theme.altus.components.SortableListHeader.columnHeight,
    minWidth: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  column: {
    height: theme.altus.components.SortableListHeader.columnHeight,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: `0 ${theme.spacing(2)}px`,
  },
  columnTitle: {
    fontSize: '1rem',
    fontWeight: 700,
    alignItems: 'center',
  },
  sortArrowIconDesc: {
    display: 'none',
  },
  sortArrowIconAsc: {
    display: 'none',
  },
  visibleArrowIcon: {
    display: 'initial',
  },
  sortedColumn: {
    background: theme.altus.components.SortableListHeader.columnBackground,
  },
  unsortedColumn: {
    '& $visibleArrowIcon': {
      display: 'none',
    },
    '&:hover $sortArrowIconAsc': {
      display: 'initial',
    },
    '&:hover': {
      background: theme.altus.components.SortableListHeader.columnBackground,
    },
  },
  sortableColumn: {
    cursor: 'pointer',
  },
});

SortableListHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      getSortProperty: PropTypes.func,
    }),
  ),
};

SortableListHeader.defaultProps = {
  columns: [],
  actions: [],
  items: EMPTY_LIST,
};

export default compose(withStyles(styles, { name: 'SortableListHeader' }))(
  SortableListHeader,
);
