import React from 'react';
import { compose } from 'redux';
import { green } from '@material-ui/core/colors';

const UpToDate = () => (
  <>
    <circle cx="12" cy="12" r="12" fill={green[700]} />
    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
  </>
);

export default compose(React.memo)(UpToDate);
