import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';

import TableRow from './TableRow';
import SearchField from '../SearchField';

const TableFilter = ({ classes, rowCount, globalFilter, setGlobalFilter }) => {
  const placeholder = `Search ${rowCount} items...`;

  return (
    <TableRow
      striped={false}
      classes={{
        root: classes.tableRowRoot,
      }}
    >
      <SearchField
        fullWidth
        value={globalFilter}
        onChange={setGlobalFilter}
        TextFieldProps={{
          placeholder,
          fullWidth: true,
        }}
        classes={{
          outline: classes.searchFieldOutline,
        }}
      />
    </TableRow>
  );
};

const styles = () => ({
  searchFieldOutline: {
    border: 0,
  },
  tableRowRoot: {},
});

TableFilter.propTypes = {
  globalFilter: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
};

export default compose(
  React.memo,
  withStyles(styles, { name: 'TableFilter' }),
)(TableFilter);
