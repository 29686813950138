import { IgcDockManagerResourceStrings } from '../components/dockmanager/dockmanager.public-interfaces';

/**
 * English resource strings for the Dock Manager
 */
export const IgcDockManagerResourceStringsEN: IgcDockManagerResourceStrings = {
  close: 'Close',
  pin: 'Pin',
  unpin: 'Unpin',
  maximize: 'Maximize',
  minimize: 'Minimize',
  moreOptions: 'More options',
  moreTabs: 'More tabs',
  panes: 'Panes',
  documents: 'Documents'
};
