import { Map } from 'immutable';

import { ACTIONS } from './confirmation.constants';

const initialState = Map();

const confirmationReducer = (state = initialState, action) => {
  const { type, ...rest } = action;

  switch (type) {
    case ACTIONS.CONFIRMATION_DIALOG_PENDING: {
      return rest;
    }

    default:
      return state;
  }
};

export default confirmationReducer;
