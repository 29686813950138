import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import withStyles from '@material-ui/styles/withStyles';
import { Avatar, Grid, Typography } from '@material-ui/core';

const DetailsHeader = ({
  Icon,
  title,
  classes,
  children,
  subtitle,
  titleUrl,
  subtitleUrl,
}) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid container item xs spacing={2} alignItems="center" wrap="nowrap">
        <Grid item xs container spacing={2}>
          {!!Icon && (
            <Grid item>
              <Avatar
                classes={{
                  colorDefault: classes.icon,
                }}
              >
                {Icon}
              </Avatar>
            </Grid>
          )}
          <Grid item xs>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  to={titleUrl}
                  component={titleUrl ? Link : undefined}
                  className={classNames(classes.text, classes.textBold, {
                    [classes.link]: titleUrl,
                  })}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  to={subtitleUrl}
                  variant="caption"
                  component={subtitleUrl ? Link : undefined}
                  className={classNames(classes.text, {
                    [classes.link]: subtitleUrl,
                  })}
                >
                  <i>{subtitle}</i>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs container justifyContent="center">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    background: 'transparent',
  },
  icon: {
    background: grey[200],
  },
  text: {
    color: theme.palette.common.white,
  },
  textBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  link: {
    'cursor': 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default compose(
  withStyles(styles, {
    name: 'DetailsHeader',
  }),
)(DetailsHeader);
