import isNil from 'lodash/isNil';
import { fromJS, Set, Map } from 'immutable';

import { ACTIONS, SLIDE_DIRECTIONS } from './modal.constants';

const initialState = fromJS({
  openModals: Map(),
  openModalId: Set(),
  direction: undefined,
  activeIndex: -1,
});

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_MODAL: {
      const { modalId } = action;

      if (modalId) {
        return state
          .set('activeIndex', action.activeIndex)
          .update('openModals', (openModals) => {
            const { value, activeIndex, replace } = action;

            if (replace) {
              return Map({
                [modalId]: Map({
                  activeIndex,
                }),
              });
            }

            if (!isNil(value)) {
              return value
                ? openModals.set(modalId, Map({ activeIndex }))
                : openModals.delete(modalId);
            }

            return openModals.has(modalId)
              ? openModals.delete(modalId)
              : openModals.set(
                  modalId,
                  Map({
                    activeIndex,
                  }),
                );
          });
      }
      return state.set('openModals', Map());
    }
    case ACTIONS.MODAL_NEXT: {
      const { modalId } = action;

      return state
        .updateIn(
          ['openModals', modalId, 'activeIndex'],
          (activeIndex) => activeIndex + 1,
        )
        .set('direction', SLIDE_DIRECTIONS.NEXT);
    }
    case ACTIONS.MODAL_PREVIOUS: {
      const { modalId } = action;

      return state
        .updateIn(
          ['openModals', modalId, 'activeIndex'],
          (activeIndex) => activeIndex - 1,
        )

        .set('direction', SLIDE_DIRECTIONS.PREVIOUS);
    }

    default:
      return state;
  }
};

export default modalReducer;
