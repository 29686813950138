import { compose } from 'redux';
import React, { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { MenuList, Typography, MenuItem } from '@material-ui/core';

import { invokeIfFunction } from '../utils';
import { EMPTY_LIST } from '../constants';

class Menu extends PureComponent {
  renderMenuItem = (item) => {
    const { renderMenuItem } = this.props;

    return invokeIfFunction(renderMenuItem, item);
  };

  render() {
    const { items, classes, emptyText } = this.props;

    return (
      <MenuList
        classes={{
          root: classes.root,
        }}
      >
        {items.map(this.renderMenuItem)}
        {!items.size && (
          <MenuItem disabled>
            <Typography variant="subtitle1" noWrap>
              <i>{emptyText}</i>
            </Typography>
          </MenuItem>
        )}
      </MenuList>
    );
  }
}

Menu.defaultProps = {
  items: EMPTY_LIST,
  emptyText: 'No items',
};

const styles = {
  root: {
    width: '100%',
  },
};

export default compose(
  withStyles(styles, {
    name: 'Menu',
  }),
)(Menu);
