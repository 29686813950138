import React from 'react';
import { compose } from 'redux';
import { Tab as MuiTab } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

const Tab = ({
  classes,
  disableRipple = true,
  disableFocusRipple = true,
  ...rest
}) => (
  <MuiTab
    classes={classes}
    disableRipple={disableRipple}
    disableFocusRipple={disableFocusRipple}
    {...rest}
  />
);

const styles = (theme) => ({
  root: {
    ...theme.typography.body2,
    'minWidth': 'auto',
    'textTransform': 'none',
    'padding': `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '&::after': {
      height: 3,
      bottom: 1,
      opacity: 0,
      content: '""',
      position: 'absolute',
      width: `calc(100% - ${theme.spacing(4)}px)`,
      borderBottom: `3px solid ${theme.altus.color.accent}`,
    },
  },
  selected: {
    '&::after': {
      opacity: 1,
    },
  },
});

export default compose(withStyles(styles, { name: 'Tab' }))(Tab);
