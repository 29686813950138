import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import Tab from './Tab';

const NavTab = ({ route, TabComponent = Tab, ...rest }) => {
  // match the route path against the current location to determine if it is selected
  const routeMatch = useRouteMatch(route);

  return <TabComponent {...rest} selected={!!routeMatch} component={NavLink} />;
};

export default NavTab;
