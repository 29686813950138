import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import { Helmet } from '../Helmet';

const ApplicationBase = ({ theme, children, appName }) => (
  <HelmetProvider>
    <ThemeProvider theme={theme}>
      <Helmet appName={appName} />
      <CssBaseline />
      {children}
    </ThemeProvider>
  </HelmetProvider>
);

export default React.memo(ApplicationBase);
