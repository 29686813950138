import { compose } from 'redux';
import React, { PureComponent } from 'react';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

class BasePageTitle extends PureComponent {
  render() {
    const { Icon, title, classes } = this.props;

    return (
      <>
        {!!Icon && <Icon className={classes.titleIcon} color="primary" />}
        <Typography className={classes.titleText} variant="h5">
          {title}
        </Typography>
      </>
    );
  }
}

const styles = (theme) => ({
  titleText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  titleIcon: {
    width: 60,
    height: 60,
    marginRight: theme.spacing(1),
  },
});

export default compose(
  withStyles(styles, {
    name: 'BasePageTitle',
  }),
)(BasePageTitle);
