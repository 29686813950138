import { useCallback, useState, useEffect } from 'react';

import { EMPTY_SET } from './constants';

const useMultiselect = (initial = EMPTY_SET) => {
  const [selectedItems, setSelectedItems] = useState(initial);

  const setInitialSelectedItems = useCallback(
    () => setSelectedItems(initial),
    [initial],
  );

  const toggleSelect = useCallback(
    (item) =>
      setSelectedItems((currentSelectedItems) =>
        currentSelectedItems.has(item)
          ? currentSelectedItems.remove(item)
          : currentSelectedItems.add(item),
      ),
    [],
  );

  const isSelected = useCallback(
    (item) => selectedItems.has(item),
    [selectedItems],
  );

  // allow the consumer to update the initial selected items if needed
  useEffect(setInitialSelectedItems, [setInitialSelectedItems]);

  return {
    isSelected,
    toggleSelect,
    selectedItems,
    clearSelection: setInitialSelectedItems,
  };
};

export default useMultiselect;
