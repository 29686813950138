import { IgcDockManagerResourceStrings } from '../components/dockmanager/dockmanager.public-interfaces';

/**
 * Korean resource strings for the Dock Manager
 */
export const IgcDockManagerResourceStringsKO: IgcDockManagerResourceStrings = {
  close: '닫기',
  pin: '고정',
  unpin: '고정 해제',
  maximize: '최대화',
  minimize: '최소화',
  moreOptions: '더 많은 옵션',
  moreTabs: '탭 더 보기',
  panes: 'Panes',
  documents: 'Documents'
};
