import { compose } from 'redux';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import makeStyles from '@material-ui/styles/makeStyles';
import { amber } from '@material-ui/core/colors';
import React, { useCallback, useRef } from 'react';
import { Grid, Typography, Tooltip } from '@material-ui/core';

import { invokeIfFunction, formatDate } from '../utils';
import { ISO_WEEK_MONDAY, DND_ITEM_TYPE, Format } from '../constants';

const CalendarDay = ({
  date,
  width,
  dateRanges,
  isSelected,
  DayComponent,
  onSelectDates,
  setSelectedRange,
  DateRangeEndComponent,
  DateRangeStartComponent,
}) => {
  const ref = useRef(null);
  const classes = useStyles({ width });

  const [, drag] = useDrag({
    canDrag: () => !!onSelectDates,
    item: {
      startDate: date,
      type: DND_ITEM_TYPE,
    },
  });

  const [, drop] = useDrop({
    canDrop: () => !!onSelectDates,
    accept: DND_ITEM_TYPE,
    hover(item) {
      if (!ref.current) {
        return;
      }

      if (item.startDate === date || item.endDate === date) {
        return;
      }

      item.endDate = date;

      setSelectedRange(item.startDate, item.endDate);
    },
    drop: (item) => {
      invokeIfFunction(onSelectDates, item.startDate, item.endDate, () =>
        setSelectedRange(null, null),
      );
    },
  });

  const onClick = useCallback(
    () =>
      setSelectedRange(date, date, () =>
        invokeIfFunction(onSelectDates, date, date, () =>
          setSelectedRange(null, null),
        ),
      ),
    [date, onSelectDates, setSelectedRange],
  );

  const dragAndDropRef = drag(drop(ref));

  return (
    <Tooltip title={formatDate(date, Format.workSchedule)}>
      <Grid
        item
        container
        ref={dragAndDropRef}
        onClick={onSelectDates ? onClick : undefined}
        className={classNames(classes.root, {
          [classes.dateSelected]: isSelected,
          [classes.dateIsToday]: date.isSame(undefined, 'day'),
        })}
      >
        {date.isoWeekday() === ISO_WEEK_MONDAY && (
          <Typography className={classes.dateWeek}>{date.isoWeek()}</Typography>
        )}
        <Typography className={classes.dateDay}>{date.date()}</Typography>
        {DayComponent && <DayComponent date={date} isSelected={isSelected} />}
        {DateRangeEndComponent && (
          <DateRangeEndComponent date={date} dateRanges={dateRanges} />
        )}
        {DateRangeStartComponent && (
          <DateRangeStartComponent date={date} dateRanges={dateRanges} />
        )}
      </Grid>
    </Tooltip>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      'padding': 1,
      'cursor': 'pointer',
      'position': 'relative',
      'width': ({ width }) => (width ? width : '100%'),
      'boxShadow': `inset 0px 0px 1px 1px ${theme.palette.grey[100]}`,
      '&:hover': {
        background: theme.palette.grey[200],
      },
    },
    dateDay: {
      zIndex: theme.zIndex.calendarDay,
      fontSize: '0.6rem',
      lineHeight: '0.6rem',
      position: 'absolute',
      right: theme.spacing(0.5),
      bottom: theme.spacing(0.5),
      color: theme.palette.text.secondary,
    },
    dateWeek: {
      zIndex: theme.zIndex.calendarWeek,
      fontSize: '0.5rem',
      lineHeight: '0.5rem',
      position: 'absolute',
      top: theme.spacing(0.5),
      left: theme.spacing(0.5),
      fontWeight: theme.typography.fontWeightBold,
    },
    dateIsToday: {
      'background': theme.palette.primary.light,
      '& $dateDay': {
        color: theme.palette.primary.contrastText,
      },
      '& $dateWeek': {
        color: theme.palette.primary.contrastText,
      },
    },
    dateSelected: {
      'background': amber[100],
      '&$dateIsToday $dateDay': {
        color: theme.palette.getContrastText(amber[100]),
      },
      '&$dateIsToday $dateWeek': {
        color: theme.palette.getContrastText(amber[100]),
      },
      '&:hover': {
        background: `${amber[100]}`,
      },
    },
  }),
  { name: 'CalendarDay' },
);

export default compose(React.memo)(CalendarDay);
