import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleModal } from './modal.actions';
import { isModalOpen } from './modal.selectors';

const useModal = (modalId) => {
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => isModalOpen(state, modalId));

  const dispatchToggleModal = useCallback(
    () => dispatch(toggleModal({ modalId })),
    [modalId, dispatch],
  );

  return [isOpen, dispatchToggleModal];
};

export default useModal;
