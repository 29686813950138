import isString from 'lodash/isString';

import { NotRequestedDataState } from './dataState.constants';

export const getDataStateSelector = (state) => state.get('dataState');

export const getActionDataStateSelector = (state, action) => {
  const { type, id } = isString(action) ? { type: action } : action;

  const actionDataState = id
    ? state.getIn(['dataState', type, id.toString()])
    : state.getIn(['dataState', type]);

  return actionDataState || NotRequestedDataState;
};

export const getActionsDataStatesSelector = (state, ...actions) =>
  actions.map((action) => getActionDataStateSelector(state, action));
