import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import CloudOff from '@material-ui/icons/CloudOff';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, Paper } from '@material-ui/core';

import BasePage from '../BasePage';

const Variants = {
  DEFAULT: 'default',
  CONTAINED: 'contained',
};

const componentByVariant = {
  [Variants.DEFAULT]: undefined,
  [Variants.CONTAINED]: Paper,
};

const NoContentBasePage = ({
  Icon,
  header,
  classes,
  variant,
  description,
  ...rest
}) => (
  <BasePage
    classes={{
      root: classes.root,
    }}
    {...rest}
  >
    <Grid item xs container justifyContent="center" alignItems="center">
      <Grid
        item
        xl={4}
        lg={6}
        md={8}
        sm={10}
        xs={12}
        component={componentByVariant[variant]}
        className={classes.container}
      >
        <Grid container spacing={2}>
          {Icon && (
            <Grid container item xs={12} justifyContent="center">
              <Icon className={classes.icon} />
            </Grid>
          )}
          <Grid container item xs={12} justifyContent="center">
            <Typography variant="h6">{header}</Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Typography variant="body2" align="justify" paragraph>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </BasePage>
);

const styles = (theme) => ({
  container: {
    padding: theme.spacing(6),
  },
  icon: {
    fontSize: theme.spacing(8),
  },
});

NoContentBasePage.defaultProps = {
  Icon: CloudOff,
  variant: Variants.DEFAULT,
};

NoContentBasePage.propTypes = {
  Icon: PropTypes.elementType,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([Variants.DEFAULT, Variants.CONTAINED]),
};

export default compose(withStyles(styles, { name: 'NoContentBasePage' }))(
  NoContentBasePage,
);
