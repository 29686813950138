import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Grid, Checkbox } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { TableRowAction, TableHeaderCell } from '../Table';

const TableHeaderRow = ({
  classes,
  columns,
  renderIcon,
  useRowSelect,
  getToggleAllRowsSelectedProps,
  TableHeaderCellComponent = TableHeaderCell,
}) => {
  return (
    <Grid container wrap="nowrap" alignItems="center" className={classes.root}>
      {!useRowSelect && renderIcon && <TableRowAction transparent />}
      {useRowSelect && (
        <TableRowAction transparent>
          <Checkbox {...getToggleAllRowsSelectedProps()} color="primary" />
        </TableRowAction>
      )}
      <Grid container item xs wrap="nowrap" className={classes.subRoot}>
        {columns.map((column) => {
          const {
            xs,
            title,
            render,
            Header,
            canSort,
            isSorted,
            isSortedDesc,
            getHeaderProps,
            getSortByToggleProps,
          } = column;

          return (
            <TableHeaderCellComponent
              {...getHeaderProps()}
              xs={xs}
              title={title}
              render={render}
              Header={Header}
              canSort={canSort}
              isSorted={isSorted}
              isSortedDesc={isSortedDesc}
              getHeaderProps={getHeaderProps}
              getSortByToggleProps={getSortByToggleProps}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    background: theme.altus.components.SortableListHeader.background,
    border: theme.altus.components.SortableListHeader.border,
  },
  subRoot: {
    overflow: 'hidden',
  },
});

TableHeaderRow.defaultProps = {
  useRowSelect: false,
};

TableHeaderRow.propTypes = {
  title: PropTypes.string,
  renderIcon: PropTypes.func,
  useRowSelect: PropTypes.bool,
  getToggleAllRowsSelectedProps: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.node,
      ]),
    }),
  ).isRequired, // see https://react-table.tanstack.com/docs/api/useTable#column-options for other column options
};

export default compose(
  React.memo,
  withStyles(styles, { name: 'TableHeaderRow' }),
)(TableHeaderRow);
