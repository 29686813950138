import React, { PureComponent } from 'react';
import { TextField } from '@material-ui/core';

import Autocomplete from './Autocomplete';

class AutocompleteField extends PureComponent {
  onChange = (_, option) => {
    const { input } = this.props;
    const { onChange } = input;

    onChange(option);
  };

  renderInput = (params) => {
    const { TextFieldProps } = this.props;

    return <TextField {...params} {...TextFieldProps} />;
  };

  render() {
    const { meta, input, renderInput, ...rest } = this.props;
    const { value = null } = input; // avoid uncontrolled > controlled field warning

    return (
      <Autocomplete
        value={value}
        onChange={this.onChange}
        renderInput={renderInput ? renderInput : this.renderInput}
        {...rest}
      />
    );
  }
}
export default AutocompleteField;
