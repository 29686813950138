import { toggleModal } from 'altus-modal';

import { CONFIRMATION_DIALOG_MODAL_ID } from './confirmation.constants';

export const toggleConfirmationDialog = () => (dispatch) =>
  dispatch(
    toggleModal({
      modalId: CONFIRMATION_DIALOG_MODAL_ID,
    }),
  );
