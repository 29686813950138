import { compose } from 'redux';
import { Route } from 'react-router-dom';
import React, { PureComponent } from 'react';
import { Grid, Fade } from '@material-ui/core';

import ApplicationSidebarMenuItem from './ApplicationSidebarMenuItem';

class ApplicationSidebarMenu extends PureComponent {
  render() {
    const { routes, ApplicationSidebarMenuItemProps } = this.props;

    return routes.map((route) => {
      const { path, routes: subRoutes } = route;

      return (
        <Route
          key={path}
          path={path}
          children={({ match }) => (
            <Grid>
              <ApplicationSidebarMenuItem
                route={route}
                depth={0}
                {...ApplicationSidebarMenuItemProps}
              />
              {!!match && (
                <Fade in={true}>
                  <Grid>
                    {subRoutes
                      .filter((route) => !route.hidden)
                      .map((subRoute) => (
                        <ApplicationSidebarMenuItem
                          match={match}
                          route={subRoute}
                          key={subRoute.path}
                          {...ApplicationSidebarMenuItemProps}
                        />
                      ))}
                  </Grid>
                </Fade>
              )}
            </Grid>
          )}
        />
      );
    });
  }
}

export default compose()(ApplicationSidebarMenu);
