import { compose } from 'redux';
import React, { PureComponent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

class SortableListRowColumn extends PureComponent {
  render() {
    const { title, classes, children, component, TypographyProps, ...rest } =
      this.props;

    return (
      <Grid
        item
        zeroMinWidth
        component={component}
        className={classes.column}
        {...(component === Typography && {
          variant: 'body2',
          noWrap: true,
          color: 'primary',
        })}
        {...TypographyProps}
        {...rest}
      >
        {children}
      </Grid>
    );
  }
}

SortableListRowColumn.defaultProps = {
  component: Typography,
};

const styles = (theme) => ({
  column: {
    overflow: 'hidden',
    padding: `0 ${theme.spacing(2)}px`,
    color: theme.altus.components.SortableListRowColumn.textColor,
  },
});

export default compose(withStyles(styles, { name: 'SortableListRowColumn' }))(
  SortableListRowColumn,
);
