import React from 'react';
import { compose } from 'redux';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, IconButton, Typography, DialogTitle } from '@material-ui/core';

const ModalHeader = ({ classes, toggleModal, title }) => {
  return (
    <DialogTitle
      disableTypography
      classes={{
        root: classes.dialogTitleRoot,
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography color="secondary" variant="subtitle2">
          {title}
        </Typography>
        {!!toggleModal && (
          <IconButton
            title="Close"
            color="inherit"
            onClick={toggleModal}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Grid>
    </DialogTitle>
  );
};

const styles = (theme) => ({
  dialogTitleRoot: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.altus.background.header,
  },
  headerButton: {
    fontWeight: 700,
    textTransform: 'none',
    background: 'transparent',
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
});

export default compose(
  withStyles(styles, {
    name: 'ModalHeader',
  }),
)(ModalHeader);
