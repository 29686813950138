export const SLIDE_DIRECTIONS = {
  NEXT: 'NEXT',
  PREVIOUS: 'PREVIOUS',
};

export const ACTIONS = {
  MODAL_NEXT: 'MODAL_NEXT',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  MODAL_PREVIOUS: 'MODAL_PREVIOUS',
};

export const KEYS = {
  ARROW_RIGHT: 'ArrowRight',
  ARROW_LEFT: 'ArrowLeft',
};
