import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import makeStyles from '@material-ui/styles/makeStyles';
import { Grid, Typography } from '@material-ui/core';

const ISO_WEEK_MONDAY = 1;

const CalendarPlaceholderDay = ({ date, width }) => {
  const classes = useStyles({ width });

  return (
    <Grid
      item
      container
      className={classNames(classes.root, {
        [classes.dateFullwidth]: !width,
      })}
    >
      {date.isoWeekday() === ISO_WEEK_MONDAY && (
        <Typography className={classes.dateWeek}>{date.isoWeek()}</Typography>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 1,
      position: 'relative',
      width: ({ width }) => (width ? width : '100%'),
      boxShadow: `inset 0px 0px 1px 1px ${theme.palette.grey[100]}`,
    },
    dateWeek: {
      fontSize: '0.5rem',
      lineHeight: '0.5rem',
      position: 'absolute',
      top: theme.spacing(0.5),
      left: theme.spacing(0.5),
      zIndex: theme.zIndex.calendarWeek,
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
  { name: 'CalendarPlaceholderDay' },
);

export default compose(React.memo)(CalendarPlaceholderDay);
