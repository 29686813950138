import { useEffect, useRef } from 'react';

/**
 * Hook that stores the previous prop or state value
 * Useful for running effects only on updates by comparing the previous and current props or state value.
 * It resembles the componentDidMount(prevProps, prevState) in class components
 *
 * @param {any} value The value to store
 */
const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
